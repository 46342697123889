import React, { useState } from 'react';
import {
  Card,
  Input,
  Button,
} from "@material-tailwind/react";
import { Block } from '@mui/icons-material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import image from '../pages/Forms/images/otp.jpeg'
import axiosBaseURL from '../userdashboard/Httpapi';

function EmployeeDetails() {
  const mystyle = {
    display: 'flex',
    width: '100%',
   
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const navigate = useNavigate();
  const[user_id, setUser_id] = useState("");
  const[ EMPLOYERNAME, setEMPLOYERNAME] = useState("");
  const[EmploymentStartDate, setEmploymentStartDate] = useState("");
  const[EmploymentEndDate, setEmploymentEndDate] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [employeeForms, setEmployeeForms] = useState([{ name: "", startDate: "", endDate: "" ,}]);


  const handleAddAnother = () => {
    setShowForm(true);
    setEmployeeForms([...employeeForms, { name: "", startDate: "", endDate: "" ,}]);
  };

  const handleDeleteForm = (index) => {
    const updatedForms = [...employeeForms];
    updatedForms.splice(index, 1);
    setEmployeeForms(updatedForms);
  };

  const handleFormChange = (index, field, value) => {
    const updatedForms = [...employeeForms];
    updatedForms[index][field] = value;
    setEmployeeForms(updatedForms);
  };
  async function save(event) {
    // event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if ( EMPLOYERNAME === '' || EmploymentStartDate === '' || EmploymentEndDate === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    // Cookies.set('Email', email, { expires: 100 });

    try {
      await axiosBaseURL.post('/EmploymentDetailsTaxpayer', {
        user_id: user_id,
        EMPLOYERNAME: EMPLOYERNAME,
        EmploymentStartDate: EmploymentStartDate,
        EmploymentEndDate: EmploymentEndDate

      }).then((res) => {
        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        } else {
          alert(' Successful');
          navigate('/SpouseEmployee');
        }
      });
    } catch (err) {
      alert(err);
    }
  }

 



  return (
    <div style={mystyle} className='justify-center min-h-screen'>
    
      {showForm && employeeForms.map((formData, index) => (
        
        <Card key={index} color="white" shadow={false}  className='pt-2 pb-4 mt-5 mb-24'>
            <h1 className='text-center'> EmployeeForm </h1>
          <form className=" p-10">
            <div className="gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
             <Input
                size="md"
                label="user_id"
                type='text'
               value={user_id}
             
                onChange={(event) => {
                  setUser_id(event.target.value);
                }}
              />
                <Input
                size="md"
                label="EMPLOYERNAME"
                type='text'
               value={EMPLOYERNAME}
             
                onChange={(event) => {
                  setEMPLOYERNAME(event.target.value);
                }}
              />
            
                
                
              <Input
                size="md"
                label="EmploymentStartDate"
                type='date'
                value={EmploymentStartDate}
              
                onChange={(event) => {
                  setEmploymentStartDate(event.target.value);
                }}
              />
              <Input
                size="lg"
                label="EmploymentEndDate"
                type='Date'
               value={EmploymentEndDate}
                onChange={(event) => {
                  setEmploymentEndDate(event.target.value);
                }}
              
              />
            </div>
            <div className='flex justify-center  flex-col mt-3  gap-5'>
            <Button variant='contained' className='' size='medium' onClick={handleAddAnother}>
          Add Another
        </Button>
       
              <Button variant='contained' size='medium' onClick={() => handleDeleteForm(index)}>
               Back
              </Button>
         
              <Button  onClick={save}>
                Contiune 
              </Button>
            
          
            </div>
            {/* <input type='submit' /> */}
          </form>
        </Card>
      ))}
   
    </div>
  )
}

export default EmployeeDetails;
