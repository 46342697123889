import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useState, } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Option,
    Select,
} from "@material-tailwind/react";
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import axiosBaseURL from '../userdashboard/Httpapi';



const med = [
    'yes',
    'no'
]

const FAccount = () => {
    const navigate = useNavigate();

    const [user_id, setUser_id] = useState("");
    const [MaximumValueOfAccountDuringCalendarYearReported, setMaximumValueOfAccountDuringCalendarYearReported] = useState("");
    const [TypeOfAccount, setTypeOfAccount] = useState("");
    const [NameOfTheFinancialInstitutionInWhichAccountIsHeld, setNameOfTheFinancialInstitutionInWhichAccountIsHeld] = useState("");
    const [MailingAddressOfBank, setMailingAddressOfBank] = useState("");

    async function save(event) {
        event.preventDefault(); // Prevent default submission

        // Check for empty fields
        if (user_id === '' || MaximumValueOfAccountDuringCalendarYearReported === '' || TypeOfAccount === '' || NameOfTheFinancialInstitutionInWhichAccountIsHeld === '' || MailingAddressOfBank === '') {
            alert('Please fill in all fields.');
            return; // Don't proceed if fields are empty
        }

        try {

            await axiosBaseURL.post('/FABRInformation', {
                user_id: user_id,
                MaximumValueOfAccountDuringCalendarYearReported: MaximumValueOfAccountDuringCalendarYearReported,
                TypeOfAccount: TypeOfAccount,
                NameOfTheFinancialInstitutionInWhichAccountIsHeld: NameOfTheFinancialInstitutionInWhichAccountIsHeld,
                MailingAddressOfBank: MailingAddressOfBank

            }, {

            }).then((res) => {
                console.log(res.data);

                if (res.status !== 200) {
                    throw new Error(`Request failed: ${res.status}`);
                } else {
                    alert('Successful');
                    navigate('/');
                }
            });
        } catch (err) {
            alert(err);
        }
    }

    return (
        <div className='min-h-screen justify-center'>
            <Card color="white"  className='pt-2 pb-4 mt-5 mb-5' shadow={false} >
            
               
                <form className="p-10" >
                <h1 className='text-center'>RETIREMENT PLANS/ADJUSTEMENTS TO INCOME </h1>
                    <div className="gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
                        <Input size="md" label="user_id" type='text'

                            value={user_id}


                            onChange={(event) => {
                                setUser_id(event.target.value);
                            }} />
                        <Input size="md" label="MaxvalofAccDuringyear" type='Date'

                            value={MaximumValueOfAccountDuringCalendarYearReported}


                            onChange={(event) => {
                                setMaximumValueOfAccountDuringCalendarYearReported(event.target.value);
                            }} />
                        <Input size="md" label="TypeOfAccount" type='text'

                            value={TypeOfAccount}


                            onChange={(event) => {
                                setTypeOfAccount(event.target.value);
                            }} />
                        <Input size="md" label="Nameoffinacialinstitutionin" type='text'

                            value={NameOfTheFinancialInstitutionInWhichAccountIsHeld}


                            onChange={(event) => {
                                setNameOfTheFinancialInstitutionInWhichAccountIsHeld(event.target.value);
                            }} />
                        <Input size="md" label="MailingAddressOfBank" type='text'

                            value={MailingAddressOfBank}


                            onChange={(event) => {
                                setMailingAddressOfBank(event.target.value);
                            }} />











                    </div>
                    <div className='flex justify-center mt-4 mr-4 '>
                        <Link to={'/'}>
                            <Button variant='contained' size='medium' onClick={save}>submit</Button>
                        </Link>
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default FAccount;
