

import React, { useState } from 'react';
import axios from 'axios';
import { Input, Button } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axiosBaseURL from '../../userdashboard/Httpapi';
import { Link } from 'react-router-dom';


const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email === '' || password === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    try {
      const response = await axiosBaseURL.post('/signin', { email, password });
      console.log(response);
      const jwtToken = response.data.jwt_token;
      // Handle the token (e.g., store it in localStorage or cookies)
      setError('');

      if (response.data.status === 200) {
        const jwtToken = response.data.jwt_token;

        // Store the JWT token in a cookie
        document.cookie = `jwt_token=${jwtToken}; path=/;`;

        console.log("User Login Successfully");
        alert("User Login Successfully");

        navigate('/TaxDashboard');
      }
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  return (
    <div>

      <html class="min-h-screen mb-64" >

        <body class="dark:bg-slate-900 bg-gray-100 flex min-h-min items-center py-12">
          <main class="w-full max-w-md mx-auto pt-0">
            <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
              <div class="p-4 pt-0 sm:p-7">
                <div class="text-center">
                  <h1 class="block text-2xl font-bold text-green-400 dark:text-white">
                    Taxoguru
                  </h1>
                  <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    Sign in Taxoguru.

                  </p>
                </div>

                <div class="mt-5">
                  <form onSubmit={handleSubmit} >
                    <div class="grid gap-y-3 place-content-center">


                      <label> Email </label>
                      <div>

                        <input type="text" className="form-control rounded" placeholder=""

                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}

                        />
                      </div>
                      <label> Password</label>
                      <div>

                        <input type="text" className="form-control rounded" placeholder=""

                          value={password}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}

                        />
                      </div>
                      <Link to="/forgotPassword" className='underline  hover:text-blue-600'>Forgot Password</Link>



                      <Button className='ml-3 lg:text-base' type="submit">Sign In</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </body>
      </html>

      {error && <p>{error}</p>}
    </div>
  );
};

export default Signin;
