import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Option,
    Select,
  } from "@material-tailwind/react";
  import Grid from '@mui/material/Grid';
  import MenuItem from '@mui/material/MenuItem';
  import image from '../pages/Forms/images/spouse.jpeg';
  import axiosBaseURL from '../userdashboard/Httpapi';


function Spouseform() {
const navigate = useNavigate();
const[user_id, setUser_id] = useState("");
const[SpouseLastName, setSpouseLastName] = useState("");
const[SpouseFirstName, setSpouseFirstName] = useState("");
const[SpouseMiddleName, setSpouseMiddleName] = useState("");
const[DOB, setDOB] = useState("");
const[SSN, setSSN] = useState("");
const[CountryOfCitizenship, setCountryOfCitizenship] = useState("");
const[visaCategory, setvisaCategory] = useState("");
const[Occupation, setOccupation] = useState("");
const[FirstDateOfEntryToUS, setFirstDateOfEntryToUS] = useState("");

const mystyle = {
  display: 'flex',
  width: '100%',
 backgroundImage: `url(${image})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};
const Types = [
    'H1-B','Green-card'

  ];
  const Country=[
    'Indian','USA','Canada'

  ];
  async function save(event) {
    event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if (  user_id === '' || SpouseLastName === '' || SpouseFirstName === '' || SpouseMiddleName === '' || DOB === '' || SSN === '' || CountryOfCitizenship === '' || visaCategory === '' || Occupation === '' || FirstDateOfEntryToUS === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    // Cookies.set('Email', email, { expires: 100 });

    try {
      await axiosBaseURL.post('/spouseDetails', {
        user_id: user_id,
        SpouseLastName: SpouseLastName,
        SpouseFirstName: SpouseFirstName,
        SpouseMiddleName: SpouseMiddleName,
        DOB: DOB,
        SSN: SSN,
        CountryOfCitizenship: CountryOfCitizenship,
        visaCategory: visaCategory,
        Occupation: Occupation,
        FirstDateOfEntryToUS: FirstDateOfEntryToUS

      }).then((res) => {
        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        } else {
          alert(' Successful');
          navigate('/Contactform');
        }
      });
    } catch (err) {
      alert(err);
    }
  }


  return (
    <div style={mystyle}  className='justify-center min-h-screen '>
       
 
      <Card color="white" className='pt-2 pb-4 mt-5 mb-5' shadow={false}>
 
      
     
      <form className="p-10">
        
      <h1 className='text-center'> Spouse Information</h1>
        <div className=" gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
        <Input size="md" label="user_id" type='text'
            
            value={user_id}
            onChange={(event) => {
              setUser_id(event.target.value);
            }}/>
        
        <Input size="md" label="SpouseLastName" type='text'
            
            value={SpouseLastName}
            onChange={(event) => {
              setSpouseLastName(event.target.value);
            }}/>
          
          <Input size="lg" label="SpouseFirstName" type='text' 
            
            value={SpouseFirstName}
            onChange={(event) => {
              setSpouseFirstName(event.target.value);
            }}/>
          <Input size="lg" label="SpouseMiddleName" type='text'
           value={SpouseMiddleName}
           onChange={(event) => {
             setSpouseMiddleName(event.target.value);
           }}/>
          <Input type="date" size="lg" label="SpouseDateofBirth"
          value={DOB}
          onChange={(event) => {
            setDOB(event.target.value);
          }}
/>
          <Input type="text" size="lg" label=" SpouseSSN"
           value={SSN}
           onChange={(event) => {
             setSSN(event.target.value);
           }} />
             <Select size="lg" label="CountryOfCitizenship"
           value={CountryOfCitizenship}
           onChange={(event) => {
             setCountryOfCitizenship(event);
           
            
           }}
        
         
            >
              {Country.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          
            <Select
      size="lg"
      label="SpouseVisaType"
      value={visaCategory}
      onChange={(event) => {
       
        setvisaCategory(event);
      }}
    >
      {Types.map((type) => (
        <Option key={type} value={type}>
          {type}
        </Option>
      ))}
    </Select>

             <Input type="text" size="lg" label=" Occupation"
           value={Occupation}
           onChange={(event) => {
             setOccupation(event.target.value);
           }} />
             <Input type="date" size="lg" label="FirstDateOfEntryToUS"
           value={FirstDateOfEntryToUS}
           onChange={(event) => {
             setFirstDateOfEntryToUS(event.target.value);
           }}
              
/>
        </div>
       
        <div className='flex justify-center mt-4 mr-4 '>
            <Link to={'/'}>
              <Button variant='contained' size='medium' onClick={save}>Next </Button>
            </Link>
          </div>
       
      </form>
    </Card>
   
    
        
        
        
        
        
        
        
        
        
        
    </div>
  )
}

export default Spouseform