import React from 'react'
import Navbar from '../component/Navbar'
import { Button } from '@material-tailwind/react'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LockIcon from '@mui/icons-material/Lock';
import Footer from '../component/Footer';
import Over from './Over';
import Final from './Final';
import BaseHeader from '../component/BaseHeader';
function Trackcalculator() {
  return (
    <div>
      <BaseHeader />
      <div className='flex flex-col  bg-light-blue-700  justify-center items-center h-96'>
        <h1 class="text-1xl  font-medium title-font mt-10 text-white text-center">Taxoguru / Personal Tax Calculator & Tools / Tax Refund Calculator</h1>
        <p className='text-base sm:text-xl text-white text-center'> Tax Calculator 2022 </p>
        <p className='text-base sm:text-xl text-white text-center'> Estimate your 2022 refund (taxes you file in 2023) with our tax calculator by answering simple
          questions about your life and income.</p>
        <a href='' className='mt-10 text-white text-base sm:text-xl'> Terms of Services </a>
        <div className='bg-light-green-400 w-full h-20 mt-5 items-center'>

          <h1 class=" text-base sm:text-xl  text-center text-white mt-7">
            Hey TurboTax customers—we started your refund estimate and it's ready to view.</h1>


        </div>
      </div>


      <div className='  bg-white  min-h-screen  sm:mt-24 mt-20  sm:justify-between  ml-10'>
        <Over />
      </div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mt-32">
            <h1 class="text-base sm:text-xl mb-4 text-gray-900"> Get more with these free tax calculators
            </h1>

          </div>
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">

                <div class="px-8 py-10 relative z-10 w-full border-4 border-blue-200 bg-white  hover:opacity-100">
                  <img src='https://digitalasset.intuit.com/IMAGE/A1LgeHvwK/taxcaster.svg' className='w-10' />
                  <a href='' className='text-base sm:text-xl text-blue-400 hover:underline'> TaxCaster Tax
                    Calculator</a>
                  <h1 class="text-base sm:text-xl text-gray-900 mb-3 ">Preview your next tax refund. TurboTax customers.
                  </h1>
                  <a href='' className='text-blue-400 text-base sm:text-xl'>  I’m aTaxogaru customer
                  </a>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">

                <div class="px-8 py-10 relative z-10 w-full border-4 border-blue-200 bg-white  hover:opacity-100">
                  <img src='https://digitalasset.intuit.com/IMAGE/A9lWvdnaM/tax-break-calculator.svg' className='w-10' />
                  <a href='' className='text-base sm:text-xl text-blue-400 hover:underline'>Tax Bracket
                    Calculator</a>
                  <h1 class="text-base sm:text-xl text-gray-900 mb-3">Easily calculate your tax rate to make smart financial decisions</h1>
                  <a href='' className='text-blue-400 text-base sm:text-xl'> Get started</a>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">

                <div class="px-8 py-10 relative z-10 w-full border-4 border-blue-200 bg-white  hover:opacity-100">
                  <img src='https://digitalasset.intuit.com/IMAGE/A6TUcpQpd/w4.svg' className='w-10' />
                  <a href='' className='text-base sm:text-xl text-blue-400 hover:underline' > W-4 Withholding Calculator</a>
                  <h1 class="text-base sm:text-xl font-medium text-gray-900 mb-3">Preview your next tax refund.TurboTax customers.</h1>
                  <a href='' className='text-blue-400 text-base sm:text-xl'> Get Started </a>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">

                <div class="px-8 py-10 relative z-10 w-full border-4 border-blue-200 bg-white  hover:opacity-100">
                  <img src='https://digitalasset.intuit.com/IMAGE/A3J7QeMce/icn-self-employed.svg' className='w-10' />
                  <a href='' className='text-base sm:text-xl text-blue-400 hover:underline' > Tax refund</a>
                  <h1 class="text-base sm:text-xl text-gray-900 mb-3">Estimate your self-employment tax and eliminate any surprises</h1>
                  <a href='' className='text-blue-400  text-base sm:text-xl'> Get started</a>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">

                <div class="px-8 py-10 relative z-10 w-full border-4 border-blue-200 bg-white  hover:opacity-100">
                  <img src='https://digitalasset.intuit.com/IMAGE/A6g0wtF9L/import-from-itsDeductible-online.svg' className='w-10' />
                  <a href='' className=' text-base sm:text-xl text-blue-400 hover:underline'>ItsDeductible™
                  </a>
                  <h1 class="text-base sm:text-xl text-gray-900 mb-3">See how much your charitable donations are worth
                  </h1>
                  <a href='' className='text-blue-400  text-base sm:text-xl'> Get started</a>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">

                <div class="px-8 py-10 relative z-10 w-full border-4 border-blue-200 bg-white  hover:opacity-100">
                  <img src='https://digitalasset.intuit.com/IMAGE/A1TQq7hbW/cryptocurrency.svg' className='w-10' />
                  <a href='' className='text-base sm:text-xl text-blue-400 hover:underline'>Crypto Calculator</a>
                  <h1 class="text-base sm:text-xl text-gray-900 mb-3">Estimate capital Taxes for cryptocurrency sales</h1>
                  <a href='' className='text-blue-400 text-base sm:text-xl'> Get started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

        <div class="grid md:grid-cols-6 gap-6">
          <div class="md:col-span-2">
            <div class="max-w-xs">
              <h2 class="text-base sm:text-xl font-bold md:text-3xl md:leading-tight dark:text-white">Frequently<br />asked questions</h2>
              <p class="mt-1 hidden md:block text-gray-600 ">Answers to the most frequently asked questions.</p>
            </div>
          </div>


          <div class="md:col-span-4">

            <div class="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700">
              <div class="hs-accordion pb-3 active" id="hs-basic-with-title-and-arrow-stretched-heading-one">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                  What are the differences between Live Assisted and Live Full Service?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-one" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
                  <div class="pr-8">
                    <p class="text-gray-600 ">
                      With Live Assisted, a team of tax experts is available to provide you with answers and advice as you do your taxes, and can review your return before you file.With Live Full Service, you're matched to a dedicated tax expert who will do your taxes, start to finish, and review them with you before filing.
                    </p>
                  </div>
                </div>
              </div>

              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-two">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
                  What qualifications do the tax experts have?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-two" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
                  <div class="pr-8">
                    <p class="text-gray-600 ">
                      Our team of US-based tax experts have extensive experience and internal training. They can handle all kinds of tax situations, from simple to complex. They'll guarantee your taxes are done right, with every dollar you deserve.
                    </p>
                  </div>
                </div>
              </div>

              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
                  When can I access a tax expert?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-three" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
                  <div class="pr-8">
                    <p class="text-gray-600 ">
                      Our tax experts are available 7 days a week from 5 AM to 9 PM PT from mid-January through the April 18 filing deadline.

                    </p>
                  </div>
                </div>
              </div>

              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-four">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-four">
                  Can I choose the expert I work with for TurboTax Live Assisted?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-four" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-four">
                  <div class="pr-8">
                    <p class="text-gray-600 dark:text-gray-400">
                      When you access Live Help, you'll enter some information about your question. This will help us direct you to the right expert to answer your question. We work hard to ensure that our tax experts are highly qualified to answer a broad range of questions, but if your question isn't answered to your satisfaction, you can ask to be transferred to a new expert.
                    </p>
                  </div>
                </div>
              </div>

              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-five">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-five">
                  What is the process like?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-five" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-five">
                  <div class="pr-8">
                    <p class="text-gray-600 dark:text-gray-400">
                      With Live Assisted, you prepare your taxes at your own pace and on your own time by answering simple questions about your life and taxes. When you need help from a tax expert, just select the “Live Help” button, and we'll connect you. You can discuss your questions over the phone, via live chat, or via one-way video on your screen. When you're done preparing your taxes, you can get a tax expert to review your return to make sure it's correct.
                    </p>
                  </div>
                </div>
              </div>

              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-six">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-six">
                  Can I see the tax expert?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-six" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-six">
                  <div class="pr-8">
                    <p class="text-gray-600 dark:text-gray-400">
                      Yes, if you choose a one-way video with your tax expert, you'll be able to see them on your screen.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div className=' flex justify-center'>
        <LockIcon />
      </div>
      <div className=' flex justify-center'>
        <h1> Your security. Built into everything we do. </h1>
      </div>
      <Footer />


















    </div>
  )
}

export default Trackcalculator