import React, { useState } from 'react';
import axios from 'axios';
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import axiosBaseURL from '../userdashboard/Httpapi';


const AdminPanel = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const mystyle = {
        display: 'flex',
        width: '100%',
        height: '100vh',
      
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (username === '' || password === '') {
            alert('Please fill in all fields.');
            return; // Don't proceed if fields are empty
        }

        try {
            const response = await axiosBaseURL.post('/admin', { username, password });
            setData(response.data.results);
            setError('');
            if (response.status !== 200) {
                throw new Error(`Request failed: ${response.status}`);
            }

            else {
                alert("Admin Registation Successfully");
                navigate('/Dashboard');
            }
        } catch (error) {
            setError('Invalid admin credentials');
            setData([]);
        }
    };

    return (
        <div style={mystyle} className='bg-light-blue-100'>
            <main class="w-full max-w-md mx-auto p-2   " >
                <div class="mt-7 ml-5 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-5 sm:p-7"></div>
                    <h1 className='ml-4 text-2xl text-blue-600 text-center'> TAXOGURU</h1>
                    <h2 className="ml-4 text-1xl text-green-300  text-center">Admin Panel</h2>

                    <form className="mt-8 mb-2 w-80  sm:w-96"  >
                        <div className="mb-4 ml-3 flex flex-col gap-6">

                            <Input size="lg" label="Username"

                                value={username}
                                onChange={(e) => setUsername(e.target.value)} />

                            <Input
                                size="lg"
                                label="Password"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                            />





                        </div>

                        <Button className='ml-3 text-center' type="submit" onClick={handleSubmit} >
                            Submit
                        </Button>

                    </form>
                </div>
            </main>

            {error && <p>{error}</p>}



        </div>
    );
};

export default AdminPanel;
