import React from 'react'
import { Link } from "react-router-dom";
import Logo from "../pages/Forms/images/Tax.png";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";

function BaseHeader() {
  const [open, setOpen] = React.useState(false);
  const [openresource, setOpenResource] = React.useState(false);
  const [opensupport, setOpenSupport] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const openresourceDrawer = () => setOpenResource(true);
  const closeresourceDrawer = () => setOpenResource(false);
  const opensupportDrawer = () => setOpenSupport(true);
  const closesupportDrawer = () => setOpenSupport(false);
  return (
    <div>


      <header class="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-white border-b border-gray-200 text-sm py-3 sm:py-0 ">
        <nav class="relative max-w-7xl w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
          <div class="flex items-center justify-between">
            <Link to="/">
              <img src={Logo} alt="" srcset="" className="w-40 sm:w-60 " />
            </Link>
            <div class="sm:hidden">
              <button type="button" class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700  align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white " data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
                <svg class="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
                <svg class="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
          <div id="navbar-collapse-with-animation" class=" pr-5 hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
            <div class="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">
              <Link onClick={openDrawer} className="mr-5  hover:text-blue-600 underline-offset-0 font-bold " aria-current="page">Expert your taxes</Link>
              <Drawer open={open} onClose={closeDrawer} className="p-4">
                <div className="mb-6  mr-5 flex items-center justify-between">
                  <Typography variant="h5" color="blue-gray">
                    Taxoguru
                  </Typography>
                  <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </IconButton>
                </div>

                <div className="flex flex-col gap-4">
                  <Link to={"/Personaltaxes"} className="hover:text-blue-600 font-bold ">  Full services For Personal Taxes </Link>
                  <Link to={"/Businesstax"} className="hover:text-blue-600  font-bold">  Full services For Business  Taxes </Link>
                </div>
              </Drawer>
              <Link onClick={openresourceDrawer} class="mr-5 hover:text-blue-600 font-bold">Resources</Link>
              <Drawer open={openresource} onClose={closeresourceDrawer} className="p-4">
                <div className="mb-6 flex items-center justify-between">
                  <Typography variant="h5" color="blue-gray ">
                    Taxoguru
                  </Typography>
                  <IconButton variant="text" color="blue-gray" onClick={closeresourceDrawer}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </IconButton>
                </div>

                <div className="flex flex-col gap-4">
                  < Link to={"/Trackcalculator"} className="hover:text-blue-600 font-bold"> Tax Refund Calucaltor </Link>

                </div>
              </Drawer>
              < Link onClick={opensupportDrawer} class="mr-5 hover:text-blue-600 font-bold"> Support </Link>
              <Drawer open={opensupport} onClose={closesupportDrawer} className="p-4">
                <div className="mb-6 flex items-center justify-between ">
                  <Typography variant="h5" color="blue-gray">
                    Taxoguru
                  </Typography>
                  <IconButton variant="text" color="blue-gray" onClick={closesupportDrawer}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </IconButton>
                </div>

                <div className="flex flex-col gap-4">
                  < Link to={"/Trackrefund"} className="hover:underline   text-blue-600 font-bold">  Track your refund  </Link>

                </div>
              </Drawer>
              <Link to="/pricing" class="mr-5 hover:text-blue-600 font-bold">Pricing</Link>
              <Link to={"/signup"}>
                <Button className='  bg-blue-600 text-white  font-semibold ' > Signup </Button>
              </Link>
              <Link to={"/signin"}>
                <Button className=' bg-blue-600 text-white  font-semibold ' > Signin </Button>
              </Link>


            </div>
          </div>
        </nav>
      </header>

    </div>
  )
}

export default BaseHeader