import React from 'react'
import { Link } from 'react-router-dom'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import Logo from "../pages/Forms/images/Taxogurulogo.png";


const Footer = () => {
  return (
    <div className='mt-5'>
      <footer class="bg-blue-800 dark:bg-gray-800 py-8 pl-10 sm:pt-6 ">
        <div class="">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div class=" grid grid-cols-2 gap-12 md:grid-cols-4 lg:grid-cols-6 lg:gap-8">
              <div class="col-span-full lg:col-span-2 ">

                <img src={Logo} className='w-52' />

                <p class=" sm:mr-10 text-white  text-base "> Copyright©1997-2023 , Inc.Taxoguru All rights reserved.Intuit, QuickBooks, QB, Taxoguru, ProConnect, and Mint are registered trademarks of Intuit.</p>
              </div>
              <div>


                <nav class="flex flex-col gap-4">
                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">About us</Link>
                  </div>

                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Join our Team</Link>
                  </div>

                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Press</Link>
                  </div>

                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Security</Link>
                  </div>
                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Privacy</Link>
                  </div>
                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Community</Link>
                  </div>
                </nav>
              </div>

              <div>


                <nav class="flex flex-col gap-4">
                  <div>
                    <Link to="" class="text-blacktransition duration-100 hover:text-indigo-500 active:text-indigo-600 text-white">Software and Licences</Link>
                  </div>



                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Trademark Notices
                    </Link>
                  </div>

                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Affiliates and Partners
                    </Link>
                  </div>

                  <div>
                    <Link to="" class="text-white transition duration-100 hover:text-indigo-500 active:text-indigo-600">Accessibility</Link>
                  </div>

                </nav>
              </div>






              <div class="py-5 text-center text-base text-white">© 2023 - Present Taxoguru. All rights reserved.</div>
              <div>

                <div>
                  <span class="inline-flex  sm:ml-auto sm:mt-0  flex-col sm:flex-row  sm:gap-0 gap-3 ml-auto justify-end sm:justify-end">
                    <a class="text-blue-500 ml-3">
                      <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                      </svg>
                    </a>
                    <a class="  ml-3 text-blue-500">
                      <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                      </svg>
                    </a>
                    <a className='ml-3'><svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="currentColor"
                      style={{ color: "#c13584" }}
                      viewBox="0 0 24 24">
                      <path
                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                    </a>
                    <a class="ml-3 text-light-blue-600" href='https://www.linkedin.com/company/taxoguru-consulting'>
                      <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
                        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                      </svg>
                    </a>
                    <a className='ml-3'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 "
                        fill="currentColor"
                        style={{ color: "#ff0000" }}
                        viewBox="0 0 24 24">
                        <path
                          d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                      </svg>
                    </a>
                  </span>


                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>


  )
}

export default Footer