import React from "react";
import { Link, Outlet } from "react-router-dom";
import Page from "./Page";

const TaxDashboard = () => {
  const Signout = () => {
    localStorage.removeItem('jwt_token');
  }

  return (
    <div className="bg-gray-50 dark:bg-slate-900">
      <div className="sticky top-0 z-20 bg-white border-b border-gray-200 px-4 sm:px-6 md:px-8 lg:hidden dark:bg-gray-800 dark:border-gray-700">
        <div className="flex items-center py-4">
          <button
            type="button"
            className="text-gray-500 hover:text-gray-600"
            data-hs-overlay="#application-sidebar"
            aria-controls="application-sidebar"
            aria-label="Toggle navigation"
          >
            <span className="sr-only">Toggle Navigation</span>
            <svg
              className="w-5 h-5"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        id="application-sidebar"
        className="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 left-0 bottom-0 z-[60] w-64 bg-white border-r border-gray-200 pt-7 pb-10 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:right-auto lg:bottom-0 dark:scrollbar-y dark:bg-gray-800 dark:border-gray-700"
      >
        <div className="px-6 text-center">
          <Link
            to={"/"}
            className="flex-none text-xl text-blue-500 font-semibold dark:text-white underline decoration decoration-blue-500"
            href="#"
            aria-label="Brand"
          >
            Taxoguru
          </Link>
        </div>

        <nav
          className="hs-accordion-group p-6 flex flex-col flex-wrap"
          data-hs-accordion-always-open
        >
          <ul className="space-y-1.5">
            <li className="hs-accordion">
              <Link
                to={"/"}
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-blue-600 text-sm text-slate-700 rounded-md hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white"
              >
                Signout
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:pl-72">
        <header>
          <Outlet />
        </header>
      </div>
    </div>
  );
};

export default TaxDashboard;
