import React from 'react'
import Navbar from '../component/Navbar'
import TourOutlinedIcon from '@mui/icons-material/TourOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import { Button } from '@material-tailwind/react';
import LockIcon from '@mui/icons-material/Lock';
import BaseHeader from '../component/BaseHeader';

function Trackrefund() {
    return (
        <div>
            <BaseHeader />
            <div class="flex flex-col text-center w-full h-60 justify-center mb-12 bg-blue-500">
                <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"> We’ll help you track your tax refund</h1>
                <p class="lg:w-2/3 mx-auto leading-relaxed text-base">After the IRS accepts your return, it typically takes about 21 days to get your refund.
                </p>
            </div>
            <div className='flex flex-wrap justify-center bg-white'>
                <h1 className='text-base sm:text-xl text-center'> Let's track your tax refund</h1>

            </div>
            <div className='flex flex-wrap justify-center bg-white mt-10'>
                <h1 className='text-base sm:text-xl text-center'> Once you receive confirmation that your federal return has been accepted,<br /> you'll be able to start
                    tracking your refund at the IRS Where's My Refund? site.</h1>


                <section class="text-gray-600 body-font">
                    <div class="container px-5 py-24 mx-auto">

                        <div class="flex flex-wrap">
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <TourOutlinedIcon color='secondary' fontSize='medium' />
                                <h1 className='text-1xl text-black'>E-filed Taxes </h1>


                            </div>
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <MarkEmailUnreadOutlinedIcon color='secondary' fontSize='medium' />
                                <h1 className='text-1xl text-black'> Return Accepted by IRS
                                </h1>


                            </div>
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <ThumbUpOffAltOutlinedIcon color='secondary' fontSize='medium' />
                                <h1 className='text-1xl text-black'> Refund Approved by IRS

                                </h1>


                            </div>
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <MonetizationOnOutlinedIcon color='secondary' fontSize='medium' />
                                <h1 className='text-1xl text-black'> Refund Sent/Deposited </h1>


                            </div>
                        </div>

                    </div>
                </section>
                <section class="text-gray-600 body-font">
                    <div class="container px-5 py-24 mx-auto">
                        <div class="flex flex-wrap -m-4">
                            <div class="p-4 lg:w-1/3">
                                <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <img src='https://digitalasset.intuit.com/IMAGE/A3aSGU5Jm/laptop.svg' className='   sm:ml-36  ml-24 mb-5' />
                                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Step 1</h1>
                                    <p class="leading-relaxed mb-3 text-2xl">If you e-filedwith Taxoguru.</p>
                                    <p> Sign in to check your federal return
                                        status and make a note of your
                                        exact refund amount.</p>
                                    <Link to="/Signin">
                                        <Button className='mt-10'> Sign in</Button>
                                    </Link>
                                </div>
                            </div>
                            <div class="p-4 lg:w-1/3">
                                <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <img src='https://digitalasset.intuit.com/IMAGE/A7zl6QGSj/checkmark.svg' className='sm:ml-36  ml-24 mb-5' />
                                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Step 2</h1>
                                    <p class="leading-relaxed mb-3 text-2xl">Track your federal
                                        tax refund</p>
                                    <p>  Once your return’s accepted, you can track your refund. If you don’t receive it within 21 days, contact the IRS.</p>
                                    <Link to="/Signin">
                                        <Button className='mt-10'> Trackrefund </Button>
                                    </Link>
                                </div>
                            </div>
                            <div class="p-4 lg:w-1/3">
                                <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <img src='https://digitalasset.intuit.com/IMAGE/A6AI0cJ45/search.svg' className='sm:ml-36  ml-24 mb-5' />
                                    <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Step 3 </h1>
                                    <p class="leading-relaxed mb-3 text-2xl">Track my
                                        state refund</p>
                                    <p> We'll help you find your
                                        state's instructions.
                                    </p>
                                    <Link to="/Signin">
                                        <Button className='mt-10'>Look Up My site</Button>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

                    <div class="grid md:grid-cols-6 gap-6">
                        <div class="md:col-span-2">
                            <div class="max-w-xs">
                                <h2 class="text-base sm:text-xl font-bold md:text-3xl md:leading-tight dark:text-white">Frequently<br />asked questions</h2>
                                <p class="mt-1 hidden md:block text-gray-600 ">Answers to the most frequently asked questions.</p>
                            </div>
                        </div>


                        <div class="md:col-span-4">

                            <div class="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700">
                                <div class="hs-accordion pb-3 active" id="hs-basic-with-title-and-arrow-stretched-heading-one">
                                    <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                                        What are the differences between Live Assisted and Live Full Service?
                                        <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                    <div id="hs-basic-with-title-and-arrow-stretched-collapse-one" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
                                        <div class="pr-8">
                                            <p class="text-gray-600 ">
                                                With Live Assisted, a team of tax experts is available to provide you with answers and advice as you do your taxes, and can review your return before you file.With Live Full Service, you're matched to a dedicated tax expert who will do your taxes, start to finish, and review them with you before filing.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-two">
                                    <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
                                        What qualifications do the tax experts have?
                                        <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                    <div id="hs-basic-with-title-and-arrow-stretched-collapse-two" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
                                        <div class="pr-8">
                                            <p class="text-gray-600 ">
                                                Our team of US-based tax experts have extensive experience and internal training. They can handle all kinds of tax situations, from simple to complex. They'll guarantee your taxes are done right, with every dollar you deserve.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                                    <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
                                        When can I access a tax expert?
                                        <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                    <div id="hs-basic-with-title-and-arrow-stretched-collapse-three" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
                                        <div class="pr-8">
                                            <p class="text-gray-600 ">
                                                Our tax experts are available 7 days a week from 5 AM to 9 PM PT from mid-January through the April 18 filing deadline.

                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-four">
                                    <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-four">
                                        Can I choose the expert I work with for TurboTax Live Assisted?
                                        <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                    <div id="hs-basic-with-title-and-arrow-stretched-collapse-four" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-four">
                                        <div class="pr-8">
                                            <p class="text-gray-600 dark:text-gray-400">
                                                When you access Live Help, you'll enter some information about your question. This will help us direct you to the right expert to answer your question. We work hard to ensure that our tax experts are highly qualified to answer a broad range of questions, but if your question isn't answered to your satisfaction, you can ask to be transferred to a new expert.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-five">
                                    <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-five">
                                        What is the process like?
                                        <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                    <div id="hs-basic-with-title-and-arrow-stretched-collapse-five" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-five">
                                        <div class="pr-8">
                                            <p class="text-gray-600 dark:text-gray-400">
                                                With Live Assisted, you prepare your taxes at your own pace and on your own time by answering simple questions about your life and taxes. When you need help from a tax expert, just select the “Live Help” button, and we'll connect you. You can discuss your questions over the phone, via live chat, or via one-way video on your screen. When you're done preparing your taxes, you can get a tax expert to review your return to make sure it's correct.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-six">
                                    <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-six">
                                        Can I see the tax expert?
                                        <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                    <div id="hs-basic-with-title-and-arrow-stretched-collapse-six" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-six">
                                        <div class="pr-8">
                                            <p class="text-gray-600 dark:text-gray-400">
                                                Yes, if you choose a one-way video with your tax expert, you'll be able to see them on your screen.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <LockIcon />
            </div>
            <div className=' flex justify-center'>
                <h1> Your security. Built into everything we do. </h1>
            </div>
            <Footer />














        </div>
    )
}

export default Trackrefund