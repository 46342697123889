import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import image from './images/taxoguru.png';
import { Link } from 'react-router-dom';
import axiosBaseURL from '../../userdashboard/Httpapi';
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from '@material-tailwind/react';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [user_id, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  const mystyle = {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  async function save(event) {
    event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if (name === '' || email === '' || user_id === '' || password === '' || phone === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    Cookies.set('Email', email, { expires: 100 });

    console.log(user_id)

    try {
      await axiosBaseURL.post('/signup', {
        name: name,
        user_id: user_id,
        email: email,
        password: password,
        phone: phone,
      }).then((res) => {
        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        } else {
          alert('User Registration Successful');
          navigate('/Otp');
        }
      });
    } catch (err) {
      alert(err);
    }
  }

  return (
    <div className=" " style={mystyle}>
      <main class="w-full max-w-md mx-auto p-2 ">
        <div class="mt-7 ml-5 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
          <div class="p-5 sm:p-7"></div>

          <Card color="white" shadow={false}>
            <Typography className="sm:text-xl text-base mb-2 text-center" color="black">
              Sign Up
            </Typography>
            <Typography color="blue" className="sm:text-xl mb-2 text-base text-center ">
              Create Account In Taxoguru
            </Typography>
            <Typography color="blue" className="sm:text-xl text-base  mb-2  text-center">
              <Link to={'/AdminPanel'}>
              <Button
                variant='gradient' 

              >
                Admin
              </Button>
              </Link>
            </Typography>



            <form className="mt-8 mb-2 pl-4 w-80 max-w-screen-lg sm:w-96">
              <div className="mb-4 ml-2 flex flex-col gap-6">
                <Input
                  size="lg"
                  label="Name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />

                <Input
                  size="lg"
                  label="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />

                <Input
                  size="lg"
                  label="User ID"
                  value={user_id}
                  onChange={(event) => {
                    setUserId(event.target.value);
                  }}
                />

                <Input
                  size="lg"
                  label="Password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />

                <Input
                  type="phone"
                  size="lg"
                  label="Phone Number"
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />
              </div>
              <Checkbox
                label={
                  <Typography
                    variant="small"
                    color="gray"
                    className="flex items-center font-normal "
                  >
                    I agree to the{' '}
                    <a
                      href="#"
                      className="font-medium transition-colors hover:text-gray-900"
                    >
                      &nbsp;Terms and Conditions
                    </a>
                  </Typography>
                }
                containerProps={{ className: '-ml-2.5' }}
              />
              <Button
                type="submit"
                className="mt-6 ml-2"
                fullWidth
                onClick={save}
              >
                Register
              </Button>
              <Typography
                color="gray"
                className="mt-4 text-center font-normal"
              >
                Already have an account?{' '}
                <Link to={"/signin"} className="font-medium text-gray-900">
                  Sign In
                </Link>
              </Typography>
            </form>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default Signup;
