import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@mui/material";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js"
// import Navbar from './component/Navbar';
// import Footer from './component/Footer';
import Signup from "./pages/Forms/Signup";
import Personaltaxes from "./pages/Personaltaxes";

import Signin from "./pages/Forms/Signin";
import Pricing from "./pages/Pricing";
import Businesstax from "./pages/Businesstax";
import Trackrefund from "./pages/Trackrefund";
import Trackcalculator from "./pages/Trackcalculator";
import Family from "./pages/Family";
import Education from "./pages/Education";





import Home from "./pages/Home";
import Income from "./pages/Income";
import General from "./pages/General";
import Donations from "./pages/Donations";
import OwnHome from "./pages/OwnHome";
import BussinessOwner from "./pages/BussinessOwner";
import Final from "./pages/Final";
import Over from "./pages/Over";
import Otp from "./pages/Otp";
import AdminPanel from "./pages/AdminPanel";
import ForgotPassword from "./pages/Forms/ForgotPassword";
import AdminDetails from "./pages/AdminDetails ";
import Dashboard from "./pages/Dashboard";
import Page from "./userdashboard/Page";

import TaxDashboard from "./userdashboard/TaxDashboard";
import Spouseform from "./Taxoguruforms/Spouseform";
import Contactform from "./Taxoguruforms/Contactform";
import EmployeeDetails from "./Taxoguruforms/EmployeeDetails";
import SpouseEmployee from "./Taxoguruforms/SpouseEmployee";
import Residency from "./Taxoguruforms/Residency";
import SpouseResidency from "./Taxoguruforms/SpouseResidency";
import OtherIncome from "./Taxoguruforms/OtherIncome";
import PersonalDetailsForm from "./Taxoguruforms/PersonalDetailsForm";
import Insrence from "./Taxoguruforms/Insrence";
import Miscellaneous from "./Taxoguruforms/Miscellaneous";
import Retairment from "./Taxoguruforms/Retairment";
import Depdentdetails from "./Taxoguruforms/Depdentdetails";




import FAccount from "./Taxoguruforms/FAccount";
import Excel from "./Taxoguruforms/Excel";
import UserProfile from "./pages/Forms/UserProfile";
import Httpapi from './userdashboard/Httpapi';

import BaseHeader from "./component/BaseHeader";








function App() {
  return (
    
    <div className="font-serif">
     
      <Router>
        {/* <Navbar /> */}


        <Routes>
          <Route exact index element={<Home />}></Route>
          <Route exact path="/signup" element={<Signup />}></Route>
       
         

          <Route exact path="/signin" element={<Signin />}></Route>
          
         


          <Route exact path="/Personaltaxes" element={<Personaltaxes/>}></Route>
          <Route exact path="/Businesstax" element={<Businesstax/>}></Route>

          <Route exact path="/Trackrefund" element={<Trackrefund />}></Route>
          <Route exact path="/Trackcalculator" element={<Trackcalculator />}></Route>
          <Route exact path="/Income" element={<Income />}></Route>
          <Route exact path="/General" element={<General />}></Route>
          <Route exact path="/Family" element={<Family />}></Route>
          <Route exact path="/Donations" element={<Donations />}></Route>
          <Route exact path="/OwnHome" element={<OwnHome />}></Route>
          <Route exact path="/BussinessOwner" element={<BussinessOwner />}></Route>

          <Route exact path="/pricing" element={<Pricing />}></Route>
          <Route exact path="/Education" element={<Education />}></Route>
          <Route exact path="/forgotPassword" element={<ForgotPassword/>}></Route>
          <Route exact path="/Httpapi" element={<Httpapi/>}></Route>
          <Route exact path="/BaseHeader" element={<BaseHeader/>}></Route>
        


     
          <Route exact path="/SpouseForm" element={<Spouseform />}></Route>
          <Route exact path="/Contactform" element={<Contactform />}></Route>
          <Route exact path="/EmployeeDetails" element={<EmployeeDetails/>}></Route>
          <Route exact path="/SpouseEmployee" element={<SpouseEmployee/>}></Route>
          <Route exact path="/Residency" element={<Residency/>}></Route>
          <Route exact path="/SpouseResidency" element={<SpouseResidency/>}></Route>
          <Route exact path="/OtherIncome" element={<OtherIncome/>}></Route>
          <Route exact path="/PersonalDetailsForm" element={<PersonalDetailsForm/>}></Route>
          <Route exact path="/Insrence" element={<Insrence/>}></Route>
          <Route exact path="/Miscellaneous" element={<Miscellaneous/>}></Route>
          <Route exact path="/Depdentdetails" element={<Depdentdetails/>}></Route>
          <Route exact path="/FAccount" element={<FAccount/>}></Route>
          <Route exact path="Excel" element={<Excel/>}></Route>
          <Route exact path="PersonalInfo" element={<Excel/>}></Route>

          <Route exact path="/Final" element={<Final />}></Route>
          <Route exact path="/Over" element={<Over />}></Route>
          <Route exact path="/Otp" element={<Otp />}></Route>
         
       
         
          <Route exact path="/AdminDetails" element={<AdminDetails/>}></Route> 
          <Route exact path="/AdminDetails/:email" element={<UserProfile/>}></Route>
          <Route exact path="/Page" element={<Page />}></Route>
          <Route exact path="/Retairment" element={<Retairment/>}></Route>
          
          <Route path="/AdminPanel" element={<AdminPanel/>} />
          

          <Route path="/dashboard" element={<Dashboard />}>
          
            <Route path="dashboard/AdminDetails" element={<AdminDetails/>} />
          </Route>
          <Route path="/taxdashboard" element={<TaxDashboard />}>
            <Route index element={<Page/>} />
            
          </Route>
        </Routes>
        
        
       


        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
