import React, { useState } from 'react';
import {
  Card,
  Input,
  Button,
} from "@material-tailwind/react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import image from '../pages/Forms/images/otp.jpeg'
import axiosBaseURL from '../userdashboard/Httpapi';

function SpouseResidency() {
  const mystyle = {
    display: 'flex',
    width: '100%',

    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const navigate = useNavigate();
  const[user_id, setUser_id] = useState("");
  const[ResidencyStateAndCityName, setResidencyStateAndCityName] = useState("");
  const[startDate, setStartDate] = useState("");
  const[endDate, setEndDate] = useState("");
  const[TotalRentpaidDuringTY2022, setTotalRentpaidDuringTY2022] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [residencyForms, setResidencyForms] = useState([{ ResidencyStateAndCityName: "", startDate: "", endDate: "" ,TotalRentpaidDuringTY2022:" "}]);

  const handleAddAnother = () => {
    setShowForm(true);
    setResidencyForms([...residencyForms, { ResidencyStateAndCityName: "", startDate: "", endDate: "" ,TotalRentpaidDuringTY2022:" "}]);
  };

 
  const handleDeleteForm = (index) => {
    const updatedForms = [...residencyForms];
    updatedForms.splice(index, 1);
    setResidencyForms(updatedForms);
  };
  async function save(event) {
    event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if (  user_id === ''|| ResidencyStateAndCityName === ''|| startDate === '' || endDate === ''|| TotalRentpaidDuringTY2022 === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    // Cookies.set('Email', email, { expires: 100 });

    try {
      await axiosBaseURL.post('/ResidencyDetailsSpouse', {
        user_id: user_id,
        ResidencyStateAndCityName: ResidencyStateAndCityName,
        startDate: startDate,
        endDate: endDate,
        TotalRentpaidDuringTY2022: TotalRentpaidDuringTY2022
      }).then((res) => {
        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        } else {
          alert('Successful');
          navigate('/');
        }
      });
    } catch (err) {
      alert(err);
    }
  }

  return (
    <div style={mystyle}  className=' justify-center min-h-screen'>
    
      {showForm && residencyForms.map((formData, index) => (
        <Card key={index} color="white" shadow={false}  className='pt-4 pb-4 mb-32 mt-5'>
          
          <form className="p-10">
          <h1 className='text-center'> SpouseResidency Form </h1>
            <div className=" gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
            <Input
                size="md"
                label="user_id"
                type='text'
                value={user_id}
                onChange={(event) => {
                  setUser_id( event.target.value);
                }}
              />
              <Input
                size="md"
                label="ResidencyStateAndCity"
                type='text'
                value={ResidencyStateAndCityName}
                onChange={(event) => {
                  setResidencyStateAndCityName( event.target.value);
                }}
              />
              <Input
                size="md"
                label="StartDate"
                type='date'
                value={startDate}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />
              <Input
                size="lg"
                label="EndDate"
                type='Date'
                value={endDate}
                onChange={(event) => {
                  setEndDate(event.target.value);
                }}
              />
                 <Input
                size="lg"
                label="TotalRentpaidDuringTY2022"
                type='text'
                value={TotalRentpaidDuringTY2022}
                onChange={(event) => {
                  setTotalRentpaidDuringTY2022( event.target.value);
                }}
              />
            </div>
            <div className='flex justify-center flex-col mt-3 gap-5'>
            <Button variant='contained' className='' size='medium' onClick={handleAddAnother}>
          Add Another
        </Button>
              <Button variant='contained' size='medium' onClick={() => handleDeleteForm(index)}>
               Back
              </Button>
              <Button onClick={save}>
                Next
              </Button>
            </div>
          </form>
        </Card>
      ))}
    
    </div>
  )
}

export default SpouseResidency;
