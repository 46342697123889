import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link, Outlet } from "react-router-dom";
import { Label } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { Button } from '@material-tailwind/react';
import axiosBaseURL from '../../userdashboard/Httpapi';

function UserProfile() {
 const[name,setName]=useState("")
 const [user_id,setUser_id]=useState("")
 const  [phone_number,setPhone_number] =useState("") 
  const {email}= useParams();
 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);




  useEffect(() => {
    
    axiosBaseURL.get(`/adminEmail/${email}`)
      .then((response) => {
        const userData = response.data; // Access the data property
        console.log(userData);
        setName(userData.name);
        setPhone_number(userData.phone);
        
      })
      .catch((error) => {
        console.log(error);
      });
      
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await  axiosBaseURL.get(`/getPersonalInfo/taxpayer/${user_id}`);

      console.log(response.data)
      const data=response.data.data;
      if(data){
      const columns = Object.keys(data);
      const values = columns.map((key) => data[key]);
      const excelData = [columns, values];

      const ws = XLSX.utils.aoa_to_sheet(excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'PersonalInfo');
      XLSX.writeFile(wb, 'personal_info.xlsx');
      }
    } catch (err) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };


  


  return (
    <body className='bg-white dark:bg-slate-900'>
    
      <div class="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-gray-800 dark:border-gray-700">
        <div class="flex items-center py-4">
          <button
            type="button"
            class="text-gray-500 hover:text-gray-600"
            data-hs-overlay="#application-sidebar"
            aria-controls="application-sidebar"
            aria-label="Toggle navigation"
          >
            <span class="sr-only">Toggle Navigation</span>
            <svg
              class="w-5 h-5"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>

          
        </div>
      </div>
      <div
        id="application-sidebar"
        class="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 left-0 bottom-0 z-[60] w-64 bg-white border-r border-gray-200 pt-7 pb-10 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:right-auto lg:bottom-0 dark:scrollbar-y dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="px-6 text-center">
          <Link
            to={"/"}
            class="flex-none text-xl text-blue-500 font-semibold dark:text-white underline decoration decoration-blue-500"
            href="#"
            aria-label="Brand"
          >
            Taxoguru
          </Link>
        </div>
      </div>
      <div class="w-full pt-10 px-4 sm:px-6 md:px-8 lg:pl-72">
        <header>
          <Outlet />
          <div className='flex flex-col gap-5'>
          <h1 className='font-bold font-serif text-2xl text-slate-700 pb-10'>Infomation</h1>
          <label className='flex flex-col text-slate-700 gap-3 '> Name
            <input type="text"  className='text-blue-600' value={name} onChange={(e)=>setName(e.target.value)} />
          </label>
          <label className='flex flex-col text-slate-700 gap-3 '> Phone Number
            <input type="text"  className='text-blue-600' value={phone_number} onChange={(e)=>setPhone_number(e.target.value)} />
          </label>
          <label className='flex flex-col text-slate-700 gap-3 '> User Id
            <input type="text"  className='text-blue-600' value={user_id}  onChange={(e)=>setUser_id(e.target.value)} />
          </label>
         </div>
         
        <div className='mt-5 gap-5 flex flex-row'>
        <Button onClick={fetchData}>Download</Button>
        <Link to={"/Dashboard"}>
        <Button variant='gradient'>Back</Button>
        </Link>
        </div>
       </header>
      </div>
   
    </body>
  );
}

export default UserProfile;
