import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import image from '../pages/Forms/images/contactdetails.jpeg';
import axiosBaseURL from '../userdashboard/Httpapi';

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Option,
  Select,
} from "@material-tailwind/react";
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

function Contactform() {
  const lovation = useLocation();
  //const user_id = this.params.user_id;
 
  console.log(lovation);
  const [Emailid, setEmailid] = useState("");
  const [PrimaryContactNumber, setPrimaryContactNumber] = useState("");
  const [AlternateContactNumber, setAlternateContactNumber] = useState("");
  const [CurrentStreetaddress, setCurrentStreetaddress] = useState("");
  const [AptNumber, setAptNumber] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const navigate = useNavigate();
  const mystyle = {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const Types = [
    'H1-B', 'Green-card'

  ];
  const Country = [
    'Indian', 'USA', 'Canada'

  ];
  async function save(event) {
    event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if (Emailid === '' || PrimaryContactNumber === '' || AlternateContactNumber === '' || CurrentStreetaddress === '' || AptNumber === '' || City === '' || State === '' || ZipCode === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    // Cookies.set('Email', email, { expires: 100 });

    try {
      const jwt_token = Cookies.get('jwt_token');
            await axiosBaseURL.post('/ContactInformation', {
        user_id:jwt_token,
        Emailid: Emailid,
        PrimaryContactNumber: PrimaryContactNumber,
        AlternateContactNumber: AlternateContactNumber,
        CurrentStreetaddress: CurrentStreetaddress,
        AptNumber: AptNumber,
        City: City,
        State: State,
        ZipCode: ZipCode
      }, {headers:{
        'Authorization':`Bearer ${jwt_token}`,
        'Content-Type':'application/json',
      }}).then((res) => {
        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        } else {
          alert('Successful');
          navigate('/Insrence')
         
        }
      });
    } catch (err) {
      alert(err);
    }
  }
  


  return (
    <div style={mystyle} className='justify-center min-h-screen '>
      
   



      <Card color="white" shadow={false} className='pt-2 pb-4 mt-5 mb-5' >

     

        <form className="p-10">
        <h1 className='text-blue-800 text-center'> Contact Form </h1>
          <div className="gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">

            <Input size="md" label="Emailid" type='text'
              value={Emailid}
               onChange={(event) => {
              setEmailid(event.target.value);
              }} />





            <Input size="lg" label="PrimaryContactNumber" type='text'

              value={PrimaryContactNumber}

              onChange={(event) => {
                setPrimaryContactNumber(event.target.value);
              }} />


            <Input size="lg" label="AlternateContactNumber" type='text' id='pc'
              value={AlternateContactNumber}

              onChange={(event) => {
                setAlternateContactNumber(event.target.value);
              }} />
            <Input size="lg" label="CurrentStreetaddress" type='text'
              value={CurrentStreetaddress}

              onChange={(event) => {
                setCurrentStreetaddress(event.target.value);
              }} />
            <Input type="text" size="lg" label="AptNumber"
              value={AptNumber}
              onChange={(event) => {
                setAptNumber(event.target.value);
              }}

            />
            <Input type="text" size="lg" label="City"
              value={City}
              onChange={(event) => {
                setCity(event.target.value);
              }}
            />

            <Input type="text" size="lg" label=" State"
              value={State}
              onChange={(event) => {
                setState(event.target.value);
              }}
            />
            <Input type="text" size="lg" label="ZipCode"
              value={ZipCode}
              onChange={(event) => {
                setZipCode(event.target.value);
              }}
            />

          </div>

          <div className='flex justify-center mt-4 mr-4 '>
         
              <Button variant='contained' size='medium' onClick={save}> Contiune </Button>
            
          </div>

        </form>
      </Card>












    </div>
  )
}

export default Contactform