import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import { useState, } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Option,
    Select,
  } from "@material-tailwind/react";
  import Grid from '@mui/material/Grid';
  import MenuItem from '@mui/material/MenuItem';
  import axiosBaseURL from '../userdashboard/Httpapi';

function OtherIncome() {
  const navigate = useNavigate();
  const lovation = useLocation();

const[HaveyousoldanystocksCapitalAssetsinUSorForeignCountry, setHaveyousoldanystocksCapitalAssetsinUSorForeignCountry] = useState("");
const[HaveyouearnedanyInterestIncomeinUSorForeignCountry, setHaveyouearnedanyInterestIncomeinUSorForeignCountry] = useState("");
const[DoyouhaveanydividendIncomeinUSorForeignCountry, setDoyouhaveanydividendIncomeinUSorForeignCountry] = useState("");
const[DoyouhaveanyRentalorBusinessIncomeexpensesinUSorForeignCountry, setDoyouhaveanyRentalorBusinessIncomeexpensesinUSorForeignCountry] = useState("");
const[DoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount,setDoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount]=useState("");


const stock = [
    'Yes','No'

  ];
  const Income=[
  'Yes','No'

  ];
  const Dividend=[
  'Yes','No'
  ]
  const Rental=[
  'Yes','No'
  ]
  const Distributions=[
  'Yes','No'
  ]
  async function save(event) {
    event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if (HaveyousoldanystocksCapitalAssetsinUSorForeignCountry === '' || HaveyouearnedanyInterestIncomeinUSorForeignCountry === '' || DoyouhaveanydividendIncomeinUSorForeignCountry === '' || DoyouhaveanyRentalorBusinessIncomeexpensesinUSorForeignCountry === '' || DoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount === '' ) {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    // Cookies.set('Email', email, { expires: 100 });

    try {
      const jwt_token = Cookies.get('jwt_token');
      await axiosBaseURL.put('/OtherIncomeDetails', {
        user_id: jwt_token,
        HaveyousoldanystocksCapitalAssetsinUSorForeignCountry: HaveyousoldanystocksCapitalAssetsinUSorForeignCountry,
        HaveyouearnedanyInterestIncomeinUSorForeignCountry: HaveyouearnedanyInterestIncomeinUSorForeignCountry,
        DoyouhaveanydividendIncomeinUSorForeignCountry: DoyouhaveanydividendIncomeinUSorForeignCountry,
        DoyouhaveanyRentalorBusinessIncomeexpensesinUSorForeignCountry: DoyouhaveanyRentalorBusinessIncomeexpensesinUSorForeignCountry,
        DoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount: DoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount
      }, {
        headers: {
          'Authorization': `Bearer ${jwt_token}`,
        }
      }) .then((res) => {
        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        } else {
          alert('Successful');
          navigate('/Retairment');
        }
      });
    } catch (err) {
      alert(err);
    }
  }

 

  return (
    <div className='justify-center min-h-screen'>
       
       
             
 
      <Card color="white"   className='pt-2 pb-4 mt-5 mb-5' shadow={false}  >
 
      
     
      <form className="p-10">
      <h1> OtherIncome</h1>
        <div className="gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
        <Select
      size="lg"
     
      label="Haveyou earned intrestIn US?"
      value={HaveyouearnedanyInterestIncomeinUSorForeignCountry}
      onChange={(event) => {
       
        setHaveyouearnedanyInterestIncomeinUSorForeignCountry(event);
      }}
    >
      {Income.map((type) => (
        <Option key={type} value={type}>
          {type}
        </Option>
      ))}
    </Select>
    <Select  
      size="lg"
      label="HaveyousoldanystockcaptialinUs?"
      value={HaveyousoldanystocksCapitalAssetsinUSorForeignCountry}
      onChange={(event) => {
       
        setHaveyousoldanystocksCapitalAssetsinUSorForeignCountry(event);
      }}
    >
      {stock.map((type) => (
        <Option key={type} value={type}>
          {type}
        </Option>
      ))}
    </Select>
    <Select
      size="lg" DoyouhaveanydividendIncomeinUSorForeignCountry
      label="DoUHaveanyincomeinUs?"
      value={DoyouhaveanydividendIncomeinUSorForeignCountry}
      onChange={(event) => {
       
        setDoyouhaveanydividendIncomeinUSorForeignCountry(event);
      }}
    >
      {Dividend.map((type) => (
        <Option key={type} value={type}>
          {type}
        </Option>
      ))}
    </Select>
    <Select
      size="lg" 
      label="DoUhaveanyrentalincomeinUS?"
      value={DoyouhaveanyRentalorBusinessIncomeexpensesinUSorForeignCountry}
      onChange={(event) => {
       
        setDoyouhaveanyRentalorBusinessIncomeexpensesinUSorForeignCountry(event);
      }}
    >
      {Rental.map((type) => (
        <Option key={type} value={type}>
          {type}
        </Option>
      ))}
    </Select>
    <Select
      size="lg" DoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount
      label="DoU'veanyDistrubionfromHSAccount"
      value={DoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount}
      onChange={(event) => {
       
        setDoyouhaveanyDistributionsfromIRAPensionAccountorHSAAccount(event);
      }}
    >
      {Distributions.map((type) => (
        <Option key={type} value={type}>
          {type}
        </Option>
      ))}
    </Select>
        </div>
       
        <div className='flex justify-center mt-4 mr-4 '>
            <Link to={'/'}>
              <Button variant='contained' size='medium' onClick={save}> Contiune </Button>
            </Link>
          </div>
       
      </form>
    </Card>
   
    
        
        
        
        
        
        
        
        
        
        
    </div>
  )
}

export default OtherIncome