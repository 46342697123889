import React, { useState } from 'react';
import axios from 'axios';
import { Input, Button } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import axiosBaseURL from '../../userdashboard/Httpapi';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSendOtp = async (event) => {
    event.preventDefault();

    try {
      await axiosBaseURL.put('/forgotPassword', { email });
      setOtpSent(true);
      setError('');
    } catch (error) {
      setError('Failed to send OTP');
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    try {
      await axiosBaseURL.post('/forgotPasswordOtp', { email, otp, password: newPassword });
      setError('');
      setOtpSent(false);
      setOtp('');
      setNewPassword('');
        {
      alert( "Password Reset Successfully");
      navigate('/');
      }
    } catch (error) {
      setError('Failed to reset password');
    }
  };

  return (
    <div className="">
      <main class="w-full max-w-md mx-auto   p-2 " >
        <div class="mt-7 ml-5 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
          <div class="p-5 sm:p-7"></div>

          <h2 className='text-2xl ml-4'>Forgot Password</h2>
          {!otpSent ? (
      
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 " onSubmit={handleSendOtp} >
              <div className="mb-4 ml-3 flex flex-col gap-6">

                <Input size="lg" label="Email"

                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />




              </div>

              <Button className='ml-3' type="submit"  >
                Submit
              </Button>

            </form>
          ) : (
          
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 " onSubmit={handleResetPassword} >
              <div className="mb-4 ml-3 flex flex-col gap-6">

                <Input size="lg" label="OTP"

                  value={otp}
                  onChange={(e) => setOtp(e.target.value)} />
                <Input size="lg" label="New Password"

                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)} />




              </div>

              <Button className='ml-3' type="submit">
                Submit
              </Button>

            </form>
          )}
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;