
import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
//import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import image from '../pages/Forms/images/personaldetails.png'
//import Cookies from 'js-cookie';
import axiosBaseURL from '../userdashboard/Httpapi';




import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";

const PersonalDetailsForm = () => {
 
  const [user_id, setuserid] = useState("");
  const [LastName, setLastName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [DOM, setDOM] = useState("");
  const [DOB, setDOB] = useState("");
  const [SSN, setSSN] = useState("");
  const [Country, setCountry] = useState("");
  const [VisaCategory, setVisaCategory] = useState("");
  const [Occupation, setOccupation] = useState("");
  const [FirstDateOfEntryToUS, setFirstDateOfEntryToUS] = useState("");
  const [DidyoueverchangedyourvisacategoryduringTY2022, setDidyoueverchangedyourvisacategoryduringTY2022] = useState("");
  const mystyle = {
    display: 'flex',
    width: '100%',
  
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const Types = [

    'H1-B', 'Green-card'



  ];
  const region = [
    'Indian'
    , 'US'

  ];
  const marrage = [
    'yes',
    'no'
  ]
  const handleSelectChange = (event) => {

    console.log(MaritalStatus)

    // setSelectedOption(selectedValue);

   

    if (MaritalStatus === "yes"){

       return navigate('/SpouseForm')

    }

    return navigate("/Contactform")

   

   

  }





  //const [token, setToken] = useState('');
  const navigate = useNavigate();
  const [SelectedOption, setSelectedOption]=useState("");


  async function save(event) {
    event.preventDefault(); // Prevent default submission
    // Cookies.set("Email",email,{expires:100})
    if (user_id === ''||LastName === ''||FirstName === ''||MiddleName === ''||MaritalStatus === ''||SSN === ''||Country === ''||VisaCategory === ''||Occupation === ''||FirstDateOfEntryToUS === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }
    
    try {
      await axiosBaseURL.post("/personalInformation", {
        user_id: user_id,
        LastName: LastName,
        FirstName: FirstName,
        MiddleName: MiddleName,
        MaritalStatus: MaritalStatus,
        DOM: DOM,
        DOB: DOB,
        SSN: SSN,
        Country: Country,
        VisaCategory: VisaCategory,
        Occupation: Occupation,
        FirstDateOfEntryToUS: FirstDateOfEntryToUS,
        DidyoueverchangedyourvisacategoryduringTY2022:DidyoueverchangedyourvisacategoryduringTY2022





      }).then((res) => {

        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`successfully`);
        }

        else {
          alert("Successfully completed");
          // navigate('/Contactform/'+user_id);
          // navigate('/Contactform', {
          //     state:{user_id:user_id}
          //   });
        }
      });

    } catch (err) {
      alert(err);
    }
    if (MaritalStatus === "yes"){

      return navigate('/SpouseForm')

   }

   navigate('/Contactform', {
    state:{user_id:user_id}
  });
  }

  return (
    <div  style={mystyle} className='justify-center min-h-screen '>

      <Card color="white"  className='pt-2 pb-4 mb-5 mt-5' shadow={false}  >
       
       

        <form className='p-10'>
        <h1 className='text-1xl text-blue-500  text-center'> PersonalDetailsForm</h1>
          <div className=" gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
           

            <Input size="lg" label=" User ID"

              value={user_id}
              onChange={(event) => {
                setuserid(event.target.value);
              }} />
            <Input size="lg" label="LastName"
              value={LastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }} />
            <Input type="FirstName" size="lg" label="FirstName"
              value={FirstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
            <Input type="MiddleName" size="lg" label="MiddleName"
              value={MiddleName}
              onChange={(event) => {
                setMiddleName(event.target.value);
              }} />
            <Select size="lg" label="MaritalStatus"

              value={MaritalStatus}

              onChange={(event) => {

                setMaritalStatus(event);





              }}





            >

              {marrage.map((type) => (

                <Option key={type} value={type}>

                  {type}

                </Option>

              ))}

            </Select>
            <Input
              type="date"
              size="lg"
              label="Date of Marriage (DOM)"
              value={DOM}
              onChange={(event) => {
                setDOM(event.target.value);
              }}
            />
            <Input type="date" size="lg" label="DOB"
              value={DOB}
              onChange={(event) => {
                setDOB(event.target.value);
              }} />
            <Input type="SSN" size="lg" label="SSN"
              value={SSN}
              onChange={(event) => {
                setSSN(event.target.value);
              }} />
            <Select size="lg" label="Country"

              value={Country}

              onChange={(event) => {

                setCountry(event);





              }}





            >

              {region.map((type) => (

                <Option key={type} value={type}>

                  {type}

                </Option>

              ))}

            </Select>
            <Input type="text" size="lg" label="Occupation"
              value={Occupation}
              onChange={(event) => {
                setOccupation(event.target.value);
              }} />

            <Select size="lg" label="VisaCategory"

              value={VisaCategory}

              onChange={(event) => {

                setVisaCategory(event);

              }}



>

              {Types.map((type) => (

                <Option key={type} value={type}>

                  {type}

                </Option>

              ))}

            </Select>


            <Input type="Date" size="lg" label="FirstDateOfEntryToUS"
              value={FirstDateOfEntryToUS}
              onChange={(event) => {
                setFirstDateOfEntryToUS(event.target.value);
              }} />
              <Select size="lg" label="Didyourvisacategory" className='p-1'

value={DidyoueverchangedyourvisacategoryduringTY2022}

onChange={(event) => {

  setDidyoueverchangedyourvisacategoryduringTY2022(event);

}}



>

{Types.map((type) => (

  <Option key={type} value={type}>

    {type}

  </Option>

))}

</Select>


          </div>
          <div className='flex justify-center mt-4 mr-4 '>

            <Link to={'/'}>

              <Button variant='contained' size='medium' onClick={save}> Contiune </Button>

            </Link>

          </div>

          <Typography color="gray" className="mt-4 text-center font-normal">


          </Typography>
        </form>
      </Card>

    </div >
  )
}

export default PersonalDetailsForm
		