import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Page() {
  return (
    <div className="p-4 sm:p-6 md:p-8">
      <div className="flex justify-end items-center gap-2 mt-3">
        <HelpCenterIcon fontSize="small" />
        <h4 className="text-sm">Help</h4>
        <SearchIcon fontSize="small" />
        <h4 className="text-sm">Search</h4>
      </div>
      <div className="border-2 border-gray-200 mt-6"></div>

      <h1 className="text-center mt-5 text-2xl">WELCOME TO TAXOGURU</h1>
      <div className="container px-4 sm:px-6 md:px-8 mx-auto flex justify-center">
        <div className="lg:w-3/4 bg-white rounded-lg p-4 sm:p-8 mt-10 md:mt-0 relative z-10 shadow-md">
          <h2 className="text-center">Here's what to expect</h2>
          <div className="flex flex-col sm:flex-row gap-4 mt-3">
            <div className="flex items-center gap-2">
              <LooksOneIcon />
              <p className="text-sm">
                Tell us about you so we can customize Taxoguru to your situation.
              </p>
            </div>
            <div className="flex items-center gap-2">
              <LooksTwoIcon />
              <p className="text-sm">
                Based on your preferences, we will show you the right prices and options.
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Looks3Icon />
              <p className="text-sm">
                Choose how you'd like to do your taxes, and we'll guide you through it.
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Link to="/PersonalDetailsForm">
              <Button variant="contained" size="medium">
                Let's go
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="flex justify-center">
          <h1 className="text-2xl text-blue-gray-700">Frequently Asked Questions</h1>
        </div>
        <div className="mt-5">
          <div class="md:col-span-4 flex justify-center mt-5">

            <div class="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700">


              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-two">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
                Can i File as a sole proprietor and single -memeber LLC on Full Service ?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-two" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
                  <div class="pr-8">
                    <p class="text-gray-600 dark:text-gray-400">
                    If you are a sole proprietor and file as a single-member LLC, you can't file in our Full Service Business tax product. Full Service Business Tax is for incorporated business owners who file their business taxes as a partnership, S-Corp, or as Multi-Member LLC. If you need to file as a sole proprietor, you'll need to file in our Taxoguru Self Employed.
                    </p>
                  </div>
                </div>
              </div>

              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
                Should I use a tax pro or Full Service
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-three" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
                  <div class="pr-8">
                    <p class="text-gray-600 dark:text-gray-400">
                    Full Service Business offers all the benefits of going to a traditional CPA without any of the inconveniences of needing to leave your home. Our business tax experts can help you as often as you need and work around your schedule to do your taxes for you
                    </p>
                  </div>
                </div>
              </div>

              <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-four">
                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-four">
                How do I Know my expert is qualified ?
                  <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                  <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                  </svg>
                </button>
                <div id="hs-basic-with-title-and-arrow-stretched-collapse-four" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-four">
                  <div class="pr-8">
                    <p class="text-gray-600 dark:text-gray-400">
                    Our business tax experts have experience and a depth of knowledge in incorporated business taxes. They're tax experts in businesses that can help partnerships (Form 1065), S-Corps (Form 1120S), or as Multimember LLC. Our tax experts have an average of 16 years of tax experience. When you connect with a business tax expert, you’ll be able to see their specific credentials and experience. Our tax experts stand by their work and proudly offer personalized service
                    </p>
                  </div>
                </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              
            
          
      
        );
}

        export default Page;
