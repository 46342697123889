import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "preline";
import "../node_modules/@headlessui/react/";
import { ThemeProvider } from "@material-tailwind/react";
import Home from "./pages/Home";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      
      <App />
     
    </ThemeProvider>
  </React.StrictMode>
);
