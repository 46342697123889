import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import axiosBaseURL from '../userdashboard/Httpapi';

const Excel = () => {
  const [user_id, setUser_id] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axiosBaseURL.get(`/getPersonalInfo/taxpayer/${user_id}`);
      console.log(response.data)
      setData(response.data.data);
    } catch (err) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    if (data) {
      const ws = XLSX.utils.json_to_sheet([data]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'PersonalInfo');
      XLSX.writeFile(wb, 'personal_info.xlsx');
    }
  };

  return (
    <div>
      <h2>Export Personal Information to Excel</h2>
      <div>
        <input
          type="text"
          placeholder="Enter User ID"
          value={user_id}
          onChange={(e) => setUser_id(e.target.value)}
        />
        <button onClick={fetchData}>Fetch Data</button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {data && (
        <div>
          {/* Display the fetched data here */}
          <button onClick={exportToExcel}>Export to Excel</button>
        </div>
      )}
    </div>
  );
};

export default Excel;
