import React, { useState } from 'react';
import {
  Card,
  Input,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import { Block } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import image from '../pages/Forms/images/dependent.jpg'
import axiosBaseURL from '../userdashboard/Httpapi';

function Depdentdetails() {
  const navigate = useNavigate();
  const [user_id, setuser_id] = useState("");
  const [DoYouHaveAnyDependantsInUSA, setDoYouHaveAnyDependantsInUSA] = useState("");
  const [DependantFirstName, setDependantFirstName] = useState("");
  const [DependantMiddleName, setDependantMiddleName] = useState("");
  const [DependantLastName, setDependantLastName] = useState("");
  const [CountryOfCitizenship, setCountryOfCitizenship] = useState("");
  const [DependantVisaCategory, setDependantVisaCategory] = useState("");
  const [DependantDateOfBirth, setDependantDateOfBirth] = useState("");
  const [SSN, setSSN] = useState("");
  const [Relationship, setRelationship] = useState("");
  const [FirstDateOfEntryToUS, setFirstDateOfEntryToUS] = useState("");
  const [DayCareExpensesIfBothTaxpayerAndSpouseAreWorking, setDayCareExpensesIfBothTaxpayerAndSpouseAreWorking] = useState("");
  const mystyle = {
    display: 'flex',
    width: '100%',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const [showForm, setShowForm] = useState(true);
  const [dependentforms, setDependentforms] = useState([{ userid: '', DoYouHaveAnyDependantsInUSA: '', DependantFirstName: '', DependantMiddleName: '', DependantLastName: '', CountryOfCitizenship: '', DependantVisaCategory: '', DependantDateOfBirth: '', SSN: '', Relationship: '', FirstDateOfEntryToUS: '', DayCareExpensesIfBothTaxpayerAndSpouseAreWorking: '' }])
  const handleAddAnother = () => {
    setShowForm(true);
    setDependentforms([...dependentforms, { userid: '', DoYouHaveAnyDependantsInUSA: '', DependantFirstName: '', DependantMiddleName: '', DependantLastName: '', CountryOfCitizenship: '', DependantVisaCategory: '', DependantDateOfBirth: '', SSN: '', Relationship: '', FirstDateOfEntryToUS: '', DayCareExpensesIfBothTaxpayerAndSpouseAreWorking: '' }]);
  };

  const handleDeleteForm = (index) => {
    const updatedForms = [...dependentforms];
    updatedForms.splice(index, 1);
    setDependentforms(updatedForms);
  };

  const handleFormChange = (index, field, value) => {
    const updatedForms = [...dependentforms];
    updatedForms[index][field] = value;
    setDependentforms(updatedForms);
  };
  async function save(event) {
    event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if (user_id === '' || DoYouHaveAnyDependantsInUSA === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

    // Cookies.set('Email', email, { expires: 100 });

    try {
      await axiosBaseURL.post('/DependentDetails', {
        user_id: user_id,
        DoYouHaveAnyDependantsInUSA: DoYouHaveAnyDependantsInUSA,
        DependantFirstName: DependantFirstName,
        DependantMiddleName: DependantMiddleName,
        DependantLastName: DependantLastName,
        CountryOfCitizenship: CountryOfCitizenship,
        DependantVisaCategory: DependantVisaCategory,
        DependantDateOfBirth: DependantDateOfBirth,
        SSN: SSN,
        Relationship: Relationship,
        FirstDateOfEntryToUS: FirstDateOfEntryToUS,
        DayCareExpensesIfBothTaxpayerAndSpouseAreWorking: DayCareExpensesIfBothTaxpayerAndSpouseAreWorking
      }).then((res) => {
        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        } else {
          alert(' Successful');
           navigate('/EmployeeDetails');
        }
      });
    } catch (err) {
      alert(err);
    }
  }
  const data = [
    'yes', 'No'
  ];





  return (
    <div style={mystyle} className='justify-center min-h-screen'>
      
      {showForm && dependentforms.map((formData, index) => (
        
        <Card key={index} color="white" shadow={false} className='pt-2 pb-4 mt-5 mb-5'>
          <h1 className='text-blue-800 text-center'> Depdentdetails Form  </h1>
          <form className="p-10 justify-center">
            <div className="gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2 ml-4  justify-center">
              <Input

                size="md"

                label="userid"

                type='text'

                value={user_id}

                onChange={(event) => {
                  setuser_id(event.target.value);
              }}

              />
              <Select
              size="lg"
        
              label="DoYouHavedependents"
              value={DoYouHaveAnyDependantsInUSA}
              
              onChange={(event) => {

                setDoYouHaveAnyDependantsInUSA(event);
              }}>
              {data.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>




              <Input

                size="md"

                label="userDependantFirstName"

                type='Text'

                value={DependantFirstName}

                onChange={(event) => {
                  setDependantFirstName(event.target.value);
              }}

              />

              <Input

                size="lg"

                label="DependantMiddleName"

                type='Text'

                value={DependantMiddleName}

                onChange={(event) => {

                  setDependantMiddleName(event.target.value);

                }}

              />

              <Input

                size="lg"

                label="DependantLastName:"

                type='Text'

                value={DependantLastName}

                onChange={(event) => {

                  setDependantLastName( event.target.value);

                }}

              />
              <Input

                size="lg"

                label="CountryOfCitizenship:"

                type='Text'

                value={CountryOfCitizenship}

                onChange={(event) => {

                  setCountryOfCitizenship(event.target.value);

                }}

              />

              <Input

                size="lg"

                label="DependantVisaCategory:"

                type='Text'

                value={DependantVisaCategory}

                onChange={(event) => {

                  setDependantVisaCategory( event.target.value);

                }}

              />

              <Input

                size="lg"

                label="DependantDateOfBirth:"

                type='date'

                value={DependantDateOfBirth}

                onChange={(event) => {

                  setDependantDateOfBirth(event.target.value);

                }}

              />

              <Input

                size="lg"

                label="SSN"

                type='Text'

                value={SSN}

                onChange={(event) => {

                  setSSN(event.target.value);

                }}

              />


              <Input

                size="lg"

                label="Relationship"

                type='Text'

                value={Relationship}

                onChange={(event) => {

                  setRelationship( event.target.value);

                }}

              />


              <Input

                size="lg"

                label="FirstDateOfEntryToUS:"

                type='Date'

                value={FirstDateOfEntryToUS}

                onChange={(event) => {

                  setFirstDateOfEntryToUS(event.target.value);

                }}

              />

              <Input className='gap-5'

                size="lg"

                label="DayCareExpensesIfBothTax"

                type='Text'

                value={DayCareExpensesIfBothTaxpayerAndSpouseAreWorking}

                onChange={(event) => {

                  setDayCareExpensesIfBothTaxpayerAndSpouseAreWorking(event.target.value);

                }}

              />

            </div>
            <div className='flex justify-center flex-col mt-3 gap-5'>
              <Button variant='contained' className='' size='medium' onClick={handleAddAnother}>
                Add Another
              </Button>
              <Button variant='contained' size='medium' onClick={() => handleDeleteForm(index)}>
                Back
              </Button>
              <Button onClick={save}>
                Contiune
              </Button>
            </div>

          </form>
        </Card>
      ))}

    </div>
  )
}

export default Depdentdetails;
