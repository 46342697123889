
import React from 'react'
import { Link ,useLocation} from 'react-router-dom'
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from "axios";
//import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import image from '../pages/Forms/images/insurance.avif'
import axiosBaseURL from '../userdashboard/Httpapi';





import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";

const Insrence= () => {
  
  const navigate = useNavigate();
  const lovation = useLocation();
  const [HealthInsurance, setHealthInsurance] = useState("");
  const [FullYearOrPartYear, setFullYearOrPartYear] = useState("");
  const [EmployerOrMarketPlace, setEmployerOrMarketPlace] = useState("");

  const mystyle = {
    display: 'flex',
    width: '100%',
    
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const year = [

   'FullYear', 'PartYear'



 ];
  const job = [
   'Employeer'
   , 'Marketplace'

 ];
 const health = [
   'yes',
   'no'
 ]





  //const [token, setToken] = useState('');
 


  async function save(event) {
    event.preventDefault(); // Prevent default submission
      // Check for empty fields
      if ( HealthInsurance === '' || FullYearOrPartYear === '' || EmployerOrMarketPlace === '' ) {
        alert('Please fill in all fields.');
        return; // Don't proceed if fields are empty
      }
    // Cookies.set("Email",email,{expires:100})
    try {
      const jwt_token = Cookies.get('jwt_token');
      await axiosBaseURL.post("/insurenceCoverageDetails", {
      user_id:jwt_token,
       HealthInsurance: HealthInsurance,
        FullYearOrPartYear: FullYearOrPartYear,
        EmployerOrMarketPlace: EmployerOrMarketPlace,
      }, {headers:{
        'Authorization':`Bearer ${jwt_token}`,
      }}).then((res) => {

        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        }

        else {
          alert(" Successfully");
          navigate('/Depdentdetails');
        }
      });

    } catch (err) {
      alert(err);
    }
  }
  return (
   <div style={mystyle} className='justify-center min-h-screen '>
     

<Card color="white" shadow={false}  className='pt-2 pb-4 mt-5 mb-52' >
  
 

  <form className="p-10">
    <h1 className='text-center'>INSURANCE COVERAGE DETAILS</h1>
    <div className="gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
     <Select size="lg" label="HealthInsurance"

value={HealthInsurance}

onChange={(event) => {

  setHealthInsurance(event);





}}



>

{health.map((type) => (

  <Option key={type} value={type}>

    {type}

  </Option>

))}

</Select>
<Select size="lg" label="FullYearOrPartYear"

value={FullYearOrPartYear}

onChange={(event) => {

  setFullYearOrPartYear(event);





}}



>

{year.map((type) => (

  <Option key={type} value={type}>

    {type}
    </Option>

))}

</Select>
<Select size="lg" label="EmployerOrMarketPlace"

value={EmployerOrMarketPlace}

onChange={(event) => {

  setEmployerOrMarketPlace(event);





}}



>

{job.map((type) => (

  <Option key={type} value={type}>

    {type}
    </Option>

))}

</Select>
</div>
<div className='flex justify-center mt-4 mr-4 '>
         
         <Button variant='contained' size='medium' onClick={save}> Contiune </Button>
       
     </div>
</form>
      </Card>

   </div>
  )
}

export default Insrence
