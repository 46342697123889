import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Typography } from "@material-tailwind/react";
import axiosBaseURL from '../userdashboard/Httpapi';

const AdminDetails = () => {
  const [adminData, setAdminData] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const TABLE_HEAD = ['Name', 'Email', 'User ID', 'Phone Number', ''];

  useEffect(() => {
    fetchAdminDetails();
  }, []);

  const fetchAdminDetails = async () => {
    try {
      const response = await axiosBaseURL.get('/adminDetails');
      console.log(response.data);
      setAdminData(response.data.results);
      setError('');
    } catch (error) {
      setError(`Error fetching admin details ${error} `);
    }
  };

  return (
    <div>
      <Card className="mt-10 ml-5 sm:ml-10 sm:max-w-screen-xl">
        <h1 className='flex justify-center text-2xl sm:text-3xl'>User Details</h1>
        <div className="overflow-x-auto">
          <table className="w-full min-w-max table-auto text-center font-serif">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-600 bg-green-200 p-2 sm:p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='border-1 border-blue-700'>
              {adminData.map((admin) => (
                <tr key={admin.id}>
                  <td className="p-2 sm:p-4">{admin.name}</td>
                  <td className="p-2 sm:p-4">{admin.email}</td>
                  <td className="p-2 sm:p-4">{admin.user_id}</td>
                  <td className="p-2 sm:p-4">{admin.phone}</td>
                  <td className="p-2 sm:p-4">
                    <Link to={"/AdminDetails/" + admin.email} className="text-1xl">
                      <button className='text-blue-600'>View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default AdminDetails;
