import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import { useState, } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Option,
    Select,
  } from "@material-tailwind/react";
  import Grid from '@mui/material/Grid';
  import MenuItem from '@mui/material/MenuItem';
  import axiosBaseURL from '../userdashboard/Httpapi';



const med = [
  'yes',
  'no'
]

const Retairment = () => {
  const navigate = useNavigate();
  const lovation = useLocation();
  const[HaveyoumadeanyIRAcontributionsorplanningtodoforTY2022, setHaveyoumadeanyIRAcontributionsorplanningtodoforTY2022] = useState("");
  const[CollagesavingsplanforyourdependentsYesorNo, setCollagesavingsplanforyourdependentsYesorNo] = useState("");
  
  async function save(event) {
    event.preventDefault(); // Prevent default submission

    // Check for empty fields
    if (HaveyoumadeanyIRAcontributionsorplanningtodoforTY2022 === '' || CollagesavingsplanforyourdependentsYesorNo === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }

  try {
    const jwt_token = Cookies.get('jwt_token');
    await axiosBaseURL.post('/RetirementPlans', {
      user_id: jwt_token,
      HaveyoumadeanyIRAcontributionsorplanningtodoforTY2022: HaveyoumadeanyIRAcontributionsorplanningtodoforTY2022,
      CollagesavingsplanforyourdependentsYesorNo:CollagesavingsplanforyourdependentsYesorNo
    }, {
      headers: {
        'Authorization': `Bearer ${jwt_token}`,
      }
    }) .then((res) => {
      console.log(res.data);

      if (res.status !== 200) {
        throw new Error(`Request failed: ${res.status}`);
      } else {
        alert('Successful');
        navigate('/FAccount');
      }
    });
  } catch (err) {
    alert(err);
  }
}

  return (
    <div className='justify-center min-h-screen'>
      <Card className='pt-2 pb-4 mt-5 ' color="white" shadow={false}>
      
        <form className="p-10" >
          <h1>RETIREMENT PLANS/ADJUSTEMENTS TO INCOME</h1>
          <div className=" gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
            <Select
              size="lg"
              label="HaveyoumadeanyIRATy2022?"
              value={HaveyoumadeanyIRAcontributionsorplanningtodoforTY2022}
              onChange={(event) => {
                setHaveyoumadeanyIRAcontributionsorplanningtodoforTY2022(event);
              }}
              
            >
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>



            <Select
              size="lg"
              className='h-10'
              label="HaveyoumadeanyContributions?"
              value={CollagesavingsplanforyourdependentsYesorNo}
              onChange={(event) => {
                setCollagesavingsplanforyourdependentsYesorNo(event);
              }}
           
            >
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>

            
            
            
              
            
           

            
            
         
          </div>
          <div className='flex justify-center mt-4 mr-4 '>
            <Link to={'/'}>
              <Button variant='contained' size='medium' onClick={save}> Contiune </Button>
            </Link>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Retairment;
