import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Card, Button, Typography, Select, Option, Medical } from '@material-tailwind/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import image from '../pages/Forms/images/missalenouis.avif'
import axiosBaseURL from '../userdashboard/Httpapi';




const Miscellaneous = () => {
  const mystyle = {
    display: 'flex',
    width: '100%',
    
    color:'orange',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const navigate = useNavigate();
  const lovation = useLocation();
  const med = [
    'yes',
    'no'
  ]
  const A1 = [
    'yes', 'No'
  ]
  const B1 = [
    'yes', 'No'
  ]
  const C1 = [
    'yes', 'No'
  ]
  const D1 = [
    'yes', 'No'
  ]
  const E1 = [
    'yes', 'No'
  ]
  const F1 = [
    'yes', 'No'
  ]
  const G1 = [
    'yes', 'No'
  ]
  const H1 = [
    'yes', 'No'
  ]
  const I1 = [
    'yes', 'No'
  ]
  const J1 = [
    'yes', 'No'
  ]


  const [MedicalexpensesYesorNo, setMedicalexpensesYesorNo] = useState('');
  const [medialexpensesusingHSAAccountYesorNo, setmedialexpensesusingHSAAccountYesorNo] = useState('');
  const [RealestatepropertytaxesinUSYesorNo, setRealestatepropertytaxesinUSYesorNo] = useState('');
  const [HomeMortgageInterestUSorForeignCountryYesorNo, setHomeMortgageInterestUSorForeignCountryYesorNo] = useState('');
  const [CharitableContributionsin2022YesorNo, setCharitableContributionsin2022YesorNo] = useState('');
  const [HaveyoupaidanyStudentLoanInterestinUSA, setHaveyoupaidanyStudentLoanInterestinUSA] = useState('');
  const [HaveyoupaidanypersonalpropertyMotorvehicletaxes, setHaveyoupaidanypersonalpropertyMotorvehicletaxes] = useState('');
  const [Nameoftaxtownordistrictforwhichvehicletaxesarepaid, setNameoftaxtownordistrictforwhichvehicletaxesarepaid] = useState('');
  const [EducationexpensesforyourselfyourSpouseorDependants, setEducationexpensesforyourselfyourSpouseorDependants] = useState('');
  const [HowdidyoufiledyourlastyeartaxreturnsItemizedOrStandard, setHowdidyoufiledyourlastyeartaxreturnsItemizedOrStandard] = useState('');

  async function save(event) {
    event.preventDefault(); // Prevent default submission
    // Check for empty fields
    if (MedicalexpensesYesorNo === '' || medialexpensesusingHSAAccountYesorNo === '' || RealestatepropertytaxesinUSYesorNo === '' || HomeMortgageInterestUSorForeignCountryYesorNo === '' || CharitableContributionsin2022YesorNo === '') {
      alert('Please fill in all fields.');
      return; // Don't proceed if fields are empty
    }
    // Cookies.set("Email",email,{expires:100})
    try {
      const jwt_token = Cookies.get('jwt_token');
      await axiosBaseURL.post("/ScheduleMedicalExpenses", {
        user_id: jwt_token,
        MedicalexpensesYesorNo: MedicalexpensesYesorNo,
        medialexpensesusingHSAAccountYesorNo: medialexpensesusingHSAAccountYesorNo,
        RealestatepropertytaxesinUSYesorNo: RealestatepropertytaxesinUSYesorNo,
        HomeMortgageInterestUSorForeignCountryYesorNo: HomeMortgageInterestUSorForeignCountryYesorNo,
        CharitableContributionsin2022YesorNo: CharitableContributionsin2022YesorNo,
        HaveyoupaidanyStudentLoanInterestinUSA: HaveyoupaidanyStudentLoanInterestinUSA,
        HaveyoupaidanypersonalpropertyMotorvehicletaxes: HaveyoupaidanypersonalpropertyMotorvehicletaxes,
        Nameoftaxtownordistrictforwhichvehicletaxesarepaid: Nameoftaxtownordistrictforwhichvehicletaxesarepaid,
        EducationexpensesforyourselfyourSpouseorDependants: EducationexpensesforyourselfyourSpouseorDependants,
        HowdidyoufiledyourlastyeartaxreturnsItemizedOrStandard: HowdidyoufiledyourlastyeartaxreturnsItemizedOrStandard


      }, {
        headers: {
          'Authorization': `Bearer ${jwt_token}`,
        }
      }).then((res) => {

        console.log(res.data);

        if (res.status !== 200) {
          throw new Error(`Request failed: ${res.status}`);
        }

        else {
          alert(" Successfully");
          navigate('/OtherIncome');
        }
      });

    } catch (err) {
      alert(err);
    }

  }


  return (
    <div  className='justify-center  min-h-screen'>
      <Card color="white" shadow={false} className='pt-2 pb-4 mt-5 mb-5'>
    
        <form className="p-10" onSubmit={save}>
          <h1 className='text-center'>  SCHEDULE A AND OTHER MISCELLANEOUS EXPENSES</h1>
          <div className="gap-4 grid sm:grid-cols-2 sm:grid-rows-4   grid-col-2 grid-row-2">
          <Select
              size="sm:lg"
              label="Haveyouincurredmedicalexpenses"
              value={MedicalexpensesYesorNo}
              onChange={(event) => {

                setMedicalexpensesYesorNo(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
              <Select
              size="lg"
              label="medialexpensesusingHSAAccount?"
              value={ medialexpensesusingHSAAccountYesorNo}
              onChange={(event) => {

                setmedialexpensesusingHSAAccountYesorNo(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select
              size="lg"
              label="RealestatepropertytaxesinUS?"
              value={RealestatepropertytaxesinUSYesorNo}
              onChange={(event) => {

                setRealestatepropertytaxesinUSYesorNo(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select
              size="lg"
              label="HomeMortgageInterestUS?"
              value={HomeMortgageInterestUSorForeignCountryYesorNo}
              onChange={(event) => {

                setHomeMortgageInterestUSorForeignCountryYesorNo(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select
              size="lg"
              label="CharitableContributionsin2022?"
              value={CharitableContributionsin2022YesorNo}
              onChange={(event) => {
                  setCharitableContributionsin2022YesorNo(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select  
              size="lg"Haveyoupaidanystudentloanintrestinusa
              label=" HaveUpaidanystudeloanintrestUSA"
              value={HaveyoupaidanyStudentLoanInterestinUSA}
              onChange={(event) => {

                setHaveyoupaidanyStudentLoanInterestinUSA(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select 
              size="lg"
              label="Haveyoupaidanypersonaltaxes?"
              value={HaveyoupaidanypersonalpropertyMotorvehicletaxes}
              onChange={(event) => {

                setHaveyoupaidanypersonalpropertyMotorvehicletaxes(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select
              size="lg"
              label="Nameoftaxtownwhichvechiclepaid"
              value={Nameoftaxtownordistrictforwhichvehicletaxesarepaid}
              onChange={(event) => {

                setNameoftaxtownordistrictforwhichvehicletaxesarepaid(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select
              size="lg" 
              label="EducationexpenseforU'rselfspousdep"
              value={EducationexpensesforyourselfyourSpouseorDependants}
              onChange={(event) => {
                    setEducationexpensesforyourselfyourSpouseorDependants(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <Select  
              size="lg"
              label="Howdidyoufiledyourlastyeartaxreturns?"
              value={HowdidyoufiledyourlastyeartaxreturnsItemizedOrStandard}
              onChange={(event) => {

                setHowdidyoufiledyourlastyeartaxreturnsItemizedOrStandard(event);
              }}>
              {med.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
            


           


          </div>
          <div className="flex justify-center mt-4 mr-4 mb-6">
            <Button variant="contained" size="medium" type="submit">
              Continue
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Miscellaneous;
