import React from 'react'
import Navbar from '../component/Navbar'
import { Button } from '@material-tailwind/react'
import GradeIcon from '@mui/icons-material/Grade';
import LockIcon from '@mui/icons-material/Lock';
import Footer from '../component/Footer';
import BaseHeader from '../component/BaseHeader';

function Personaltaxes() {
    return (
        <div>
            <BaseHeader />

            <section class="text-gray-600 bg-gray-100   ">


                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center mb-10 gap-5">

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base  md:text-xl text-black mb-8'> FULL SERVICE </h1>
                        <p className='text-base  md:text-xl text-black mb-8'> Let a tax expert do taxesfor <br />you, start to finish</p>
                        <p className='text-base  md:text-xl text-black mb-8'> Your dedicated tax expert will find every dollar
                            you deserve, guaranteed</p>
                        <div className='flex ' >
                            <Button size='small' variant='contained' color='red' className=' gap-10'> Start For Free <br /> Pay only when you File </Button>

                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src="https://images.pexels.com/photos/6964349/pexels-photo-6964349.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    </div>

                </div>

            </section>
            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center mb-10 gap-5">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src="https://i.pcmag.com/imagery/roundups/035mDvgVNR803Klh9GbmeaY-12..v1644422496.jpg" />
                    </div>

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base  md:text-xl text-black mb-8'> Your tax expert will find everydollar you deserve</h1>
                        <p className='text-base  md:text-xl text-black mb-8'> When your Full Service expert does your taxes, they’ll only sign and file when they know it's 100% correct and you’re getting the best outcome possible, guaranteed.

                        </p>

                        <div className='flex ' >
                            <Button size='small' variant='contained' color='green' className=' border-red-400 gap-10'> see Price </Button>

                        </div>
                    </div>


                </div>

            </section>
            <section class="text-gray-600 bg-gray-100  ">


                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center mb-10 gap-5">

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='ttext-base  md:text-xl text-black mb-8'>Get matched with an expert and <br />stay with them next year </h1>

                        <p className='text-base  md:text-xl text-black mb-8'> Work with someone you get to know and trust, <br />who can help you make the best decisions based on your situation. <br />You can even request to have them do your taxes again next year.</p>
                        <div className='flex ' >
                            <Button size='small' variant='contained' color='green' className=' gap-10'> See Price  </Button>

                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src="https://www.taxoutreach.org/wp-content/uploads/person-does-taxes.jpg" />
                    </div>

                </div>

            </section>
            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5  md:flex-row flex-col items-center mb-10 gap-5">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src="https://digitalasset.intuit.com/IMAGE/A0oAWMWK4/ray_refund.jpg" />
                    </div>

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base  md:text-xl text-black mb-8'>We’re India’s #1 tax prep provider</h1>
                        <p className='text-base  md:text-xl text-black mb-8'> Each year we help more people file their taxes than all other online tax prep providers combined.
                        </p>

                        <div className='flex ' >
                            <Button size='small' variant='contained' color='red' className=' border-red-400 gap-10'> see Price Pay only When you File  </Button>

                        </div>
                    </div>


                </div>

            </section>
            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-wrap -m-4">
                        <div class="p-4 lg:w-1/3">
                            <div class="h-full bg-light-blue-600 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                <h2 class="tracking-widest text-base sm:text-xl  text-white mb-1">FullService | Basic</h2>
                                <h1 class="title-font  text-base sm:text-1xl font-medium text-white mb-3">Simple tax returns only See if you qualify</h1>
                                <p class="leading-relaxed mb-3 text-white text-base md:text-xl">$219  <br />State additional.</p>
                                <Button variant='gradient'> Start For Free</Button>
                            </div>
                        </div>
                        <div class="p-4 lg:w-1/3">
                            <div class="h-full bg-light-blue-600 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                <h2 class="tracking-widest text-base sm:text-xl  text-white mb-1">FullService | Deluxe</h2>
                                <h1 class="title-font  text-base sm:text-1xl font-medium text-white mb-3">Deductions for home,donations and unemployment income</h1>
                                <p class="leading-relaxed mb-3 text-white text-base md:text-xl">$269 <br />State additional.</p>
                                <Button variant='gradient'> Start For Free</Button>
                            </div>
                        </div>
                        <div class="p-4 lg:w-1/3">
                            <div class="h-full bg-light-blue-600 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                <h2 class="tracking-widest text-base sm:text-xl  text-white mb-1">FullService | Premium</h2>
                                <h1 class="title-font  text-base sm:text-1xl font-medium text-white mb-3">Investors   and self-employed   and   Employee Income</h1>
                                <p class="leading-relaxed mb-3 text-white text-base md:text-xl">$418 <br />State additional.</p>
                                <Button variant='gradient'> Start For Free</Button>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-wrap -m-4">
                        <div class="p-4 lg:w-1/3">
                            <div class="h-full bg-gray-300 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                <h2 class="tracking-widest text-base sm:text-xl  text-black mb-1">Taxoguru was amazing ! </h2>
                                <h1 class=" text-base sm:text-1xl font-medium text-black mb-3">“Taxoguru helps me file my taxes quickly. My tax preparer was very knowledgeable on helping me understand my return. She was able to educate me more on self employment, which is my first time filing with self employment.”</h1>
                                <p class=" text-black  text-base ">Philly anna, Arizona2023 Full Service Premium</p>

                            </div>
                        </div>
                        <div class="p-4 lg:w-1/3">
                            <div class="h-full bg-gray-300 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                <h2 class="tracking-widest text-base sm:text-xl  text-black mb-1">Easier than we thought. Bernadette was GREAT! </h2>
                                <h1 class=" text-base sm:text-1xl font-medium text-black mb-3">"We started our own business and after we got the runaround with our CPA we decided to use TurboTax. It was super easy. We did have to scan and upload our docs but it didn't take long. Bernadette we great. She answered all the questions I had, and was super friendly. ”</h1>
                                <p class=" text-black  text-base ">JoelTR, Massachusetts
                                    2023 Full Service Premium</p>

                            </div>
                        </div>
                        <div class="p-4 lg:w-1/3">
                            <div class="h-full bg-gray-300 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                <h2 class="tracking-widest text-base sm:text-xl  text-black mb-1">Taxoguru  was awesome </h2>
                                <h1 class=" text-base sm:text-1xl font-medium text-black mb-3">“I will never do my taxes myself as long as TurboTax full service is available. My tax expert walked us through every step ensuring all our questions were answered.”</h1>
                                <p class=" text-black  text-base ">Tpilot, North Carolina
                                    2023 Full Service Basic
                                </p>

                            </div>
                        </div>


                    </div>
                </div>
            </section>






            <div className=' flex justify-center'>
                <LockIcon />
            </div>
            <div className=' flex justify-center'>
                <h1 className='text-base  md:text-xl'> Your security. Built into everything we do. </h1>
            </div>






            <Footer />



        </div>
    )
}

export default Personaltaxes