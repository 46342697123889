import React from 'react'
import { useState } from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import { Button } from '@mui/material'
import LiveTvRoundedIcon from '@mui/icons-material/LiveTvRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LockIcon from '@mui/icons-material/Lock';
import Signin from './Forms/Signin';
import image from '../pages/Forms/images/Home.png'
import BaseHeader from '../component/BaseHeader';

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axiosBaseURL from '../userdashboard/Httpapi'
import { Link } from 'react-router-dom';


const Home = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [token, setToken] = useState('');
    const navigate = useNavigate();




    const mystyle = {
        display: 'flex',
        width: '100%',
        height: '80vh',
        //backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        opacity: 2,
        color: 'black',


        position: 'absolute'

    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (email != '' || password != '') {
                const response = await axiosBaseURL.post('/signin', { email, password });
                const jwtToken = response.data.jwt_token;
                // Handle the token (e.g., store it in localStorage or cookies)
                setError('');

                if (response.status === 200) {
                    const jwtToken = response.data.jwt_token;

                    // Store the JWT token in a cookie
                    document.cookie = `jwt_token=${jwtToken}; path=/;`;

                    console.log("User Login Successfully");
                    alert("User Login Successfully");

                    navigate('/TaxDashboard');
                }
            } else {
                alert('Please fill in all fields.');
            }
        } catch (error) {
            setError('Invalid email or password');
        }
    };

    return (
        <div>
            <header class="text-gray-600 body-font bg-blue-800 " >
                <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">

                    <p className='text-white text-center'> Oct 16  is the he IRS deadline– plan &File Your Taxes with Expert  <Link to="/signup" className='underline  hover:text-blue-600'>Register Now</Link></p>
                </div>
            </header>
            <BaseHeader />
            <div class="bg-white">
                <div class="container px-0 py-0 sm:px-0 sm:py-0  mx-auto flex items-right sm:flex-row flex-col ">


                    <span class="inline-flex  sm:ml-auto  p-3  mr-7 justify-end sm:justify-end">
                        <a class="text-light-blue-500 ml-3">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a>
                        <a class=" ml-3 text-light-blue-500">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                            </svg>
                        </a>
                        <a className='ml-3'><svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="currentColor"
                            style={{ color: "#c13584" }}
                            viewBox="0 0 24 24">
                            <path
                                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                        </svg>
                        </a>
                        <a class="ml-3 text-light-blue-500" href='https://www.linkedin.com/company/taxoguru-consulting'>
                            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
                                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                            </svg>
                        </a>
                        <a className='ml-2'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5 "
                                fill="currentColor"
                                style={{ color: "#FF0000" }}
                                viewBox="0 0 24 24">
                                <path
                                    d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                            </svg>
                        </a>
                    </span>

                </div>
            </div>


            <div className='md:flex-row sm:flex   flex flex-col items-center md:justify-center justify-center bg-white gap-x-4 w-full'>

                <div className='md:w-1/4 w-11/12 '>

                    <h1 className='sm:text-2xl text-basel'>File your taxes (With in 72 Hours)</h1>

                    <p>Reduce your tax liability and maximize your returns</p>
                    <Button variant='contained'> Start For Free</Button>


                </div>

                <div className='md:w-2/5 w-11/12  sm:mb-0 mt-5'>

                    <img src={image} alt="" />

                </div>
                <div class="mt-5 bg-white border md:w-1/4   w-11/12  border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-4 pt-0 sm:p-7">
                        <div class="text-center">
                            <h1 class="block text-2xl font-bold text-green-400 dark:text-white">
                                Taxoguru
                            </h1>
                            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                                Sign in Taxoguru.

                            </p>
                        </div>

                        <div class="mt-5">
                            <form onSubmit={handleSubmit} >
                                <div class="grid gap-y-3">
                                    {/* gap-y-3 place-content-center */}


                                    <label> Email </label>
                                    <div>

                                        <input type="text" className="form-control rounded  w-4/5" placeholder=""

                                            value={email}
                                            onChange={(event) => {
                                                setEmail(event.target.value);
                                            }}

                                        />
                                    </div>
                                    <label> Password</label>
                                    <div>

                                        <input type="text" className="form-control rounded w-4/5" placeholder=""

                                            value={password}
                                            onChange={(event) => {
                                                setPassword(event.target.value);
                                            }}

                                        />
                                    </div>
                                    <Link to="/forgotPassword" className='underline  hover:text-blue-600'>Forgot Password</Link>



                                    <Button variant='contained' className='ml-3 lg:text-base' type="submit">Sign In</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>



            </div>
            <div class="flex flex-wrap w-full justify-center py-10 ">

                <p class="lg:w-1/2 w-full leading-relaxed text-balck  sm:text-2xl text-base  text-center"> America's Afferable & Reliable Tax Prep</p>
            </div>







            <section class="text-gray-600 body-font ">
                <div class="container px-5 py-0   mx-auto">

                    <div class="flex flex-wrap -m-4 justify-center  ">
                        <div class="xl:w-1/3 p-4 justify-center">
                            <div class="bg-light-blue-700 p-6 rounded-lg  ">

                                <h3 class="tracking-widest text-white text-base   text-center">Happy Clients </h3>
                                <img class="h-40 rounded w-full object-cover object-center mb-6" src="https://digitalasset.intuit.com/IMAGE/A67J5S97g/spot-high-five.svg" alt="content" />
                                <h2 class="text-lg text-white  font-medium title-font text-center mb-4">6000 + files</h2>
                                <p class="leading-relaxed text-base   text-white  text-center">Successful files  taxes for completed 6000+ clients </p>
                            </div>
                        </div>
                        <div class="xl:w-1/3 p-4 sm:pr-0  justify-center">
                            <div class="bg-light-blue-700  p-6 rounded-lg">
                                <h3 class="tracking-widest text-white text-base   text-center">TAT</h3>
                                <img class="h-40  rounded w-full object-cover object-center mb-6" src="https://digitalasset.intuit.com/IMAGE/A1A5sOGDT/spot-money-time.svg" alt="content" />
                                <h2 class=" text-white text-center     text-base   text- mb-4">Within 72 Hours</h2>
                                <p class="leading-relaxed text-base  text-white text-center ">Expert Tax Estimates meet  in 3 Business days With</p>
                            </div>
                        </div>
                        <div class="xl:w-1/3 p-4 justify-center">
                            <div class="bg-light-blue-700  p-6 rounded-lg">
                                <h3 class="tracking-widest text-white text-base   text-center">Top Rated</h3>
                                <img class="h-40  rounded w-full object-cover object-center mb-6" src="https://digitalasset.intuit.com/IMAGE/A50jXs0j7/Frame-369988.svg" alt="content" />
                                <h2 class=" text-white text-center     text-base   mb-4">4.7/5</h2>
                                <p class="leading-relaxed text-base  text-white text-center">Happy & Successful clients who rated  as in 5 stars</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>



            <div className='h-48 py-10  bg-white-100 flex flex-wrap justify-center items-center gap-15 pb-20'>
                <div className='p-3 flex flex-col gap-4 sm:pt-0 justify-between items-center'>
                    <p className='text-base-1   lg:text-2xl  font-bold text-red-700 text-center '>Only At Taxoguru </p>
                    <p className="lg:text-lg md:text-sm "> Your tax return, backed for lifeTM</p>
                    <p className="lg:text-lg md:text-sm text-center"> Only at Taxoguru Tax guarantees your tax return for its full lifetime of 7 years with 100% accurate calculations, audit support, and your maximum refund.</p>

                </div>
            </div>


            <section class="text-gray-600 body-font">
                <div class="container px-5 py-10 mx-auto flex flex-wrap">
                    <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature" class="object-cover object-center " src="https://images.pexels.com/photos/8297474/pexels-photo-8297474.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    </div>
                    <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div class="flex flex-col mb-10 lg:items-start items-center">

                            <div class="flex-grow">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Experienced</h2>
                                <p class="leading-relaxed text-base">Taxexperts and CPAs with years of experience do your taxes for you</p>

                            </div>
                        </div>
                        <div class="flex flex-col mb-10 lg:items-start items-center">

                            <div class="flex-grow">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"> Real Time </h2>
                                <p class="leading-relaxed text-base">Work with your tax expert in real time and avoid the "wait and wonder".</p>

                            </div>
                        </div>
                        <div class="flex flex-col mb-10 lg:items-start items-center">

                            <div class="flex-grow">
                                <h2 class="text-gray-900 text-lg title-font font-medium mb-3"> Guaranteed</h2>
                                <p class="leading-relaxed text-base">Know you'll get every dollar you deserve with our FullService Guarantee.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='flex justify-center  flex-col  mt-5'>
                <p className=' text-base   text-center '> Join the millions who file smarter™ </p>
                <p className='text-base   text-center '> Rated 4.5 out of 5 stars by our customers</p>
            </div>

            <section class="text-gray-600 body-font flex">
                <div class="container px-5 py-24 mx-auto">

                    <div class="flex flex-wrap md:space-y-0 space-y-6">
                        <div class="p-4 pt-0 md:w-1/3 flex">
                            <div class="flex-grow pl-6">
                                <p class="text-gray-900 text-base  title-font  mb-2 font-bold">Great solution to file tax returns</p>
                                <p class="leading-relaxed text-base   ">"I've used Turbotax for close to 10 years. Each year I've noticed tremendous improvements. They have taken initiative to make handling crypto currency's tax filings easy, intuitive, and very helpful. Without Taxoguru, I highly recommend using Taxoguru to anyone filing taxes."</p>
                                <p className='text-red-600 text-base   '> J1J2J3J4J5, California2023 TurboTax Premium</p>
                            </div>
                        </div>
                        <div class="p-4  pt-0 md:w-1/3 flex">

                            <div class="flex-grow pl-6 gap-5">
                                < p class="text-gray-900 text-base   title-font  mb-2 font-bold">Taxoguru was amazing!!</p>
                                <p class="leading-relaxed text-base    ">"TurboTax helps me file my taxes quickly. My tax preparer was very knowledgeable on helping me understand my return. She was able to educate me more on self employment, which is my first time filing with self employment."</p>
                                <p className='text-red-600 text-base   '> Philly anna, Arizona2023 Full Service Premium</p>
                            </div>
                        </div>
                        <div class="p-4 pt-0 md:w-1/3 flex">

                            <div class="flex-grow pl-6">
                                <p class="text-gray-900 text-base   title-font  mb-2 font-bold"> Taxoguru is my go-to now </p>
                                <p class="leading-relaxed text-base   "> "This is my first year filing my tax return online. TurboTax was amazing- the tips & useful information made it so easy."</p>
                                <p className='text-red-600 text-base   '> Devon90, North Carolina2023 Taxoguru Deluxe</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='flex justify-center mb-5'>
                <p className='text-base sm:text-lg  text-center mr-3   font-bold'>Your satisfaction, guaranteed </p>
            </div>


            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">

                    <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                        <div class="p-4 md:w-1/3 flex flex-col text-center items-center gap-3">
                            <div>
                                <a class="block relative h-40   rounded overflow-hidden">
                                    <img class="object-cover object-center  rounded " src="https://digitalasset.intuit.com/IMAGE/A9LFbdhrm/Badge_Maximum-Refund-Guarantee-2.svg" />
                                </a>
                            </div>
                            <div class="flex-grow">

                                <p class="leading-relaxed text-base text-black">We’ll find every tax deduction and credit you qualify for to boost your tax refund.</p>

                            </div>
                        </div>
                        <div class="p-4 md:w-1/3 flex flex-col text-center items-center gap-3">
                            <div >
                                <a class="block relative h-40   rounded overflow-hidden">
                                    <img class="object-cover object-center rounded w-40" src="https://digitalasset.intuit.com/IMAGE/A7xjJz1Cn/complete-check.svg" />
                                </a>

                            </div>
                            <div class="flex-grow">

                                <p class="leading-relaxed text-base  text-black ">Get a comprehensive review of your online tax return.</p>

                            </div>
                        </div>
                        <div class="p-4 md:w-1/3 flex flex-col text-center items-center gap-3">
                            <div >
                                <a class="block relative h-40 rounded overflow-hidden  ">
                                    <img class="object-cover object-center rounded" src="https://digitalasset.intuit.com/IMAGE/A7NI7ZeuF/Badge_100_-Accurate-Calculations-Guarantee.svg.svg" />
                                </a>
                            </div>
                            <div class="flex-grow">

                                <p class="leading-relaxed text-base text-black   ">Our calculations are 100% accurate on your tax return, or we’ll pay anyIRS penalties.</p>

                            </div>
                        </div>
                        <div class="p-4 md:w-1/3 flex flex-col text-center items-center gap-3">
                            <div>
                                <a class="block relative h-40 rounded overflow-hidden ">
                                    <img alt="ecommerce" class="object-cover object-center rounded w-40" src=" https://digitalasset.intuit.com/IMAGE/A6g6YxIQe/BigLifeChanges-badge.svg" />
                                </a>
                            </div>
                            <div class="flex-grow">

                                <p class="leading-relaxed text-base text-black ">Got married?Had a baby?Bought a home?TurboTax can help find you any new tax deductions and credits.</p>

                            </div>
                        </div>
                        <div class="p-4 md:w-1/3 flex flex-col text-center items-center gap-3">
                            <div >
                                <a class="block relative h-40   rounded overflow-hidden">
                                    <img class="object-cover object-center rounded w-40" src="https://digitalasset.intuit.com/IMAGE/A9z2QaJl3/Badge_Audit-Defense.svg" />
                                </a>

                            </div>
                            <div class="flex-grow">

                                <p class="leading-relaxed text-base  text-black">Every personal TurboTax return is backed by our Audit Support Guarantee for free guidance from a trained tax professional.</p>

                            </div>
                        </div>
                        <div class="p-4 md:w-1/3 flex flex-col text-center items-center gap-3">
                            <div >
                                <a class="block relative h-40 rounded overflow-hidden  ">
                                    <img class="object-cover object-center rounded w-40" src="https://digitalasset.intuit.com/IMAGE/A8pyvS1EY/eFile-badge.svg" />
                                </a>
                            </div>
                            <div class="flex-grow">

                                <p class="leading-relaxed text-base text-black">File taxes electronically (e-file) and get email confirmation when your tax return is accepted.</p>

                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <div className=' flex justify-center'>
                <LockIcon />
            </div>
            <div className=' flex justify-center'>
                <h1> Your security. Built into everything we do. </h1>
            </div>




            <Footer />
        </div>



    )
}

export default Home