import React from 'react'
import { Button } from '@material-tailwind/react'
import Navbar from '../component/Navbar'
import GradeIcon from '@mui/icons-material/Grade';
import Footer from '../component/Footer';
import LockIcon from '@mui/icons-material/Lock';
import BaseHeader from '../component/BaseHeader';

function Businesstax() {
    return (
        <div>


            <BaseHeader />


            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center mb-10 gap-5">

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base sm:text-lg md:text-xl text-black mb-8'> FULL SERVICE | Business </h1>
                        <div className='flex flex-row '>
                            <h1 className='text-base sm:text-lg md:text-xl text-red-700'> NEW!</h1>
                            <p className='text-base sm:text-lg md:text-xl'>  Let a business tax expert do everything for you–start to finish</p>

                        </div>
                        <p className='text-base sm:text-lg md:text-xl text-black mb-8'> Our experts are available now to prepare, sign, and file your business taxes and get you every credit & deduction you deserve, guaranteed.</p>
                        <h1 className='text-base sm:text-lg md:text-xlmb-5'> $1,499</h1>

                        <div className='flex ' >
                            <Button size='small' variant='contained' color='red' className=' gap-10'> Grt Started </Button>

                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" src="https://media.istockphoto.com/id/1335043843/photo/home-finances.jpg?s=612x612&w=0&k=20&c=G8PSD6s6x7N1-DnWf0tCTsm0B93UvU_HE6JUZF37UTM=" />
                    </div>

                </div>

            </section>
            <div className='flex flex-wrap justify-center bg-gray-100'>
                <h1 className='text-base sm:text-lg md:text-xl'> How Full Service Business works:</h1>

            </div>
            <div className='flex flex-wrap justify-center bg-gray-100 mb-10'>
                <h1 className='text-base sm:text-lg md:text-xl'> Perfect for Partnerships, S-corps, and Multi-Member LLCs</h1>
                <section class="text-gray-600 body-font">
                    <div class="container px-5 py-24 mx-auto">

                        <div class="flex flex-wrap">
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <img src=' https://digitalasset.intuit.com/IMAGE/A0XdIQDyc/icn-upgrade-to-cloud.png' className='w-18' />
                                <h1 className='text-base sm:text-lg md:text-xl text-black'> 1. Securely upload your
                                    tax docs</h1>
                                <p class="leading-relaxed text-base sm:text-lg md:text-xl mb-4"> To get started, simply share your necessary business tax info to help us match you with the right expert.

                                </p>

                            </div>
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <img src='https://digitalasset.intuit.com/IMAGE/A4k9zlOFR/expert.png' className='w-18' />
                                <h1 className='text-base sm:text-lg md:text-xl text-black'> 2. Get matched and meet with your own business tax expert
                                </h1>
                                <p class="leading-relaxed text-base sm:text-lg md:text-xl mb-4">Based on your unique needs, we’ll match you with a dedicated expert who understands your business situation.</p>

                            </div>
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <img src=' https://digitalasset.intuit.com/IMAGE/A4hvMErqd/live-expert.png ' className='w-20' />
                                <h1 className='text-base sm:text-lg md:text-xl text-black'> 3. Enjoy unlimited
                                    expert access
                                </h1>
                                <p class="leading-relaxed text-base sm:text-lg md:text-xl mb-4"> Your expert will keep you updated on their progress and will be available for advice and answers as often as you need.</p>

                            </div>
                            <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                                <img src=' https://digitalasset.intuit.com/IMAGE/A8Gcvo2uD/review-taxes.png' className='w-20' />
                                <h1 className='text-base sm:text-lg md:text-xl text-black'> 4. Review and confirm everything together</h1>
                                <p class="leading-relaxed text-base sm:text-lg md:text-xl mb-4"> Your expert will go over your return with you before they file, and back it with an Expert Approved Guarantee.



                                </p>

                            </div>
                        </div>

                    </div>
                </section>

            </div>
            <div className='justify-center flex mb-10'>
                <h1 className='text-base sm:text-lg md:text-xl'> See why our customers trust Full Service Business </h1>
            </div>

            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5  md:flex-row flex-col items-center mb-10 gap-5">

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base sm:text-lg md:text-xl text-black mb-8'> The right business tax <br />expert to handle your business tax needs </h1>
                        <div className='flex flex-row '>

                            <p className='text-base sm:text-lg md:text-xl'>  We’ll get to know you and your business, then match you with a dedicated expert who specializes in incorporated businesses and will help ensure yours is benefitting from the latest tax laws.</p>

                        </div>


                        <div className='flex mt-10 ' >
                            <Button size='lg' variant='contained' color='white' className=' gap-10 bg-blue-gray-50'>Start For Free <br /> Pay only When you File</Button>

                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" src=" https://digitalasset.intuit.com/IMAGE/A4gsSHyR7/zoneC-1.png" />
                    </div>

                </div>

            </section>
            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5  md:flex-row flex-col items-center mb-10 gap-5">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" src=" https://digitalasset.intuit.com/IMAGE/A5AGaiBWa/zoneD-3.png" />
                    </div>

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base sm:text-lg md:text-xl text-black mb-8'> Unlimited, year-round advice and answers
                        </h1>
                        <div className='flex flex-row '>

                            <p className='text-base sm:text-lg md:text-xl'>  and answers
                                No need to wonder if your accountant will ever get back to you. Or if there’s a limit to how often you can talk with them. Simply reach out to an expert whenever you need, including nights and weekends, at no extra cost.

                            </p>

                        </div>


                        <div className='flex mt-10 ' >
                            <Button size='lg' variant='contained' color='white' className=' gap-10 bg-blue-gray-50'>Start For Free </Button>

                        </div>
                    </div>


                </div>

            </section>
            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5  md:flex-row flex-col items-center mb-10 gap-5">

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base sm:text-lg md:text-xl text-black mb-8'> Our business tax experts ensure you get every credit and deduction available </h1>
                        <div className='flex flex-row '>

                            <p className='text-base sm:text-lg md:text-xl'>  Our specialized business tax experts will help uncover ways to save on your taxes, maximize deductions, and find all your industry-specific tax credits you deserve.</p>

                        </div>


                        <div className='flex mt-10 ' >
                            <Button size='lg' variant='contained' color='white' className=' gap-10 bg-blue-gray-50'>Start For Free </Button>

                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" src=" https://digitalasset.intuit.com/IMAGE/A7bCofQg2/zoneE-2.png" />
                    </div>

                </div>

            </section>
            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5  md:flex-row flex-col items-center mb-10 gap-5">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" src=" https://digitalasset.intuit.com/IMAGE/A0xDbPxli/Expert_approved_badge.svg" />
                    </div>

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base sm:text-lg md:text-xl text-black mb-8'> Backed by our 100% Accurate, Expert Approved Guarantee
                        </h1>
                        <div className='flex flex-row '>

                            <p className='text-base sm:text-lg md:text-xl'> Get your business taxes done right knowing your return is backed by America’s #1 tax prep provider. If you pay an IRS penalty because of an error made by a Taxoguru Live expert, we’ll pay you the penalty plus interest.</p>

                        </div>


                        <div className='flex mt-10 ' >
                            <Button size='lg' variant='contained' color='white' className=' gap-10 bg-blue-gray-50'>Start For Free </Button>

                        </div>
                    </div>


                </div>

            </section>
            <section class="text-gray-600 bg-white ">


                <div class="container mx-auto flex px-5  md:flex-row flex-col items-center mb-10 gap-5">

                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-9 md:mb-0 items-center text-center">
                        <h1 className='text-base sm:text-lg md:text-xl text-black mb-8'>  Enjoy extra peace of mind </h1>
                        <div className='flex flex-row '>

                            <p className='text-base sm:text-lg md:text-xl'>  Audits by the IRS can be random. If by chance you’re audited, you won’t have to worry because a dedicated expert will be there to represent you and communicate with the IRS or state agencies on your behalf–at no extra cost</p>

                        </div>


                        <div className='flex mt-10 ' >
                            <Button size='lg' variant='contained' color='white' className=' gap-10 bg-blue-gray-50'>Start For Free </Button>

                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" src=" https://digitalasset.intuit.com/IMAGE/A0ZAdl4Yo/Zone-G.png" />
                    </div>

                </div>

            </section>
            <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

                <div class="grid md:grid-cols-6 gap-6">
                    <div class="md:col-span-2">
                        <div class="max-w-xs">
                            <h2 class="text-base sm:text-xl font-bold md:text-3xl md:leading-tight dark:text-white">Frequently<br />asked questions</h2>
                            <p class="mt-1 hidden md:block text-gray-600 ">Answers to the most frequently asked questions.</p>
                        </div>
                    </div>


                    <div class="md:col-span-4">

                        <div class="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700">
                            <div class="hs-accordion pb-3 active" id="hs-basic-with-title-and-arrow-stretched-heading-one">
                                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                                    What are the differences between Live Assisted and Live Full Service?
                                    <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-one" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
                                    <div class="pr-8">
                                        <p class="text-gray-600 ">
                                            With Live Assisted, a team of tax experts is available to provide you with answers and advice as you do your taxes, and can review your return before you file.With Live Full Service, you're matched to a dedicated tax expert who will do your taxes, start to finish, and review them with you before filing.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-two">
                                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
                                    What qualifications do the tax experts have?
                                    <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-two" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
                                    <div class="pr-8">
                                        <p class="text-gray-600 ">
                                            Our team of US-based tax experts have extensive experience and internal training. They can handle all kinds of tax situations, from simple to complex. They'll guarantee your taxes are done right, with every dollar you deserve.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
                                    When can I access a tax expert?
                                    <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-three" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
                                    <div class="pr-8">
                                        <p class="text-gray-600 ">
                                            Our tax experts are available 7 days a week from 5 AM to 9 PM PT from mid-January through the April 18 filing deadline.

                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-four">
                                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-four">
                                    Can I choose the expert I work with for TurboTax Live Assisted?
                                    <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-four" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-four">
                                    <div class="pr-8">
                                        <p class="text-gray-600 dark:text-gray-400">
                                            When you access Live Help, you'll enter some information about your question. This will help us direct you to the right expert to answer your question. We work hard to ensure that our tax experts are highly qualified to answer a broad range of questions, but if your question isn't answered to your satisfaction, you can ask to be transferred to a new expert.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-five">
                                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-five">
                                    What is the process like?
                                    <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-five" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-five">
                                    <div class="pr-8">
                                        <p class="text-gray-600 dark:text-gray-400">
                                            With Live Assisted, you prepare your taxes at your own pace and on your own time by answering simple questions about your life and taxes. When you need help from a tax expert, just select the “Live Help” button, and we'll connect you. You can discuss your questions over the phone, via live chat, or via one-way video on your screen. When you're done preparing your taxes, you can get a tax expert to review your return to make sure it's correct.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-six">
                                <button class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-six">
                                    Can I see the tax expert?
                                    <svg class="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <svg class="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <div id="hs-basic-with-title-and-arrow-stretched-collapse-six" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-six">
                                    <div class="pr-8">
                                        <p class="text-gray-600 dark:text-gray-400">
                                            Yes, if you choose a one-way video with your tax expert, you'll be able to see them on your screen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className=' flex justify-center'>
                <LockIcon />
            </div>
            <div className=' flex justify-center'>
                <h1 className='text-base sm:text-lg md:text-xl'> Your security. Built into everything we do. </h1>
            </div>

            <Footer />





        </div>
    )
}

export default Businesstax