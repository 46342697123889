import React, { useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import image from './Forms/images/otp.jpeg'
import axiosBaseURL from '../userdashboard/Httpapi';
const Otp= () => {
 
  const [otp, setOTP] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const navigate = useNavigate();
  const mystyle = {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const handleOTPVerification = async () => {
    const Email=Cookies.get('Email');
    try {
      const response = await axiosBaseURL.post('/verifyOTP', {
      
        otp: otp,
        email:Email
      });
      if (response.status === 200) {
        setVerificationMessage("OTP verified successfully");
        alert('OTP verified successfully');
        navigate('/Signin');
      } else {
        setVerificationMessage('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
  <div class="max-w-6xl py-10 px-4 sm:px-6 lg:px-8 lg:py-16 mx-auto" style={mystyle}>
  <div class="max-w-xl text-center mx-auto">
    <div class="mb-5">
      <h2 class="text-2xl font-bold md:text-3xl md:leading-tight dark:text-white">OTP Verfication </h2>
    </div>

  
      <div class="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
        <div class="w-full">
          
          <input type="text"  class="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" placeholder="Enter your OTP"
          value={otp}
          onChange={(e) => setOTP(e.target.value)}/>
        </div>
       
        <button onClick={handleOTPVerification}>Verify OTP</button>
       <p>{verificationMessage}</p>
      </div>
  
  </div>
</div>
  );
};

export default Otp;
